import { UserService } from 'src/api/base/user/user.service';
import { DialogService } from './../../../services/dialog.service';
import { Router } from '@angular/router';
import { ProblemService } from './../../../services/problem.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Problem } from 'src/Models/problem';
import { Solution } from 'src/Models/solution';
import { SolutionService } from 'src/services/solution.service';
import { faCommentAlt, faDoorOpen, faEdit, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { AccessService } from 'src/services/access.service';

@Component({
  selector: 'app-SearchTableSolution',
  templateUrl: './SearchTableSolution.component.html',
  styleUrls: ['./SearchTableSolution.component.css']
})
export class SearchTableSolutionComponent implements OnInit {
  faCommentAlt = faCommentAlt;
  faUnlockAlt = faDoorOpen;
  faEdit = faEdit;
  solutions = new MatTableDataSource<Solution>();
  displayedColumns: string[] = 
  [
  'solutionId',
  'organizationName',
  'area',
  'objectives',
   'description', 
   'trl', 
   'actions'
  ];
  isOrganizationOwner = false;
  user: any;
  

  constructor(private _solution: SolutionService,
    private _dialog: DialogService,
    private _user: UserService,
    private _access: AccessService,
    private router: Router) { }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.loadData();

  }

  loadData() {

    this._solution.getAll()
    .subscribe(result =>{
      this.solutions = new MatTableDataSource<Solution>(result.filter((solution: Solution) => solution.status == true));
    })
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.solutions.filter = filterValue.trim().toLowerCase();
  }

  openDialog(element?: any) {
  this.router.navigate(['/newSolution']);
    // const dialogRef = this.dialog.open(InsertProblemComponent);
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  openDialogProposal(element?: any) {
    const dialogRef = this._dialog.openProposal(undefined, element as Solution);
  }

  delete(element: any) {
    this._solution.deleteSolution(element.solutionId).subscribe(
      () => {
        console.log('Solution deleted successfully!');
        this.loadData();
      },
      error => {
        console.log(error);
      }
    )
  }

  chargeDetails(data: any){
    //check if the data is a problem or a solution
    if(data.problemId){
      this._dialog.openDialogForTableInfo(data, undefined);
    } else {
      this._dialog.openDialogForTableInfo(undefined, data);
  }
}

}
