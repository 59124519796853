import { DialogService } from 'src/services/dialog.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Organization } from 'src/Models/organization';
import { OrganizationService } from 'src/services/organization.service';
import { TypeOrganizationService } from 'src/services/typeOrganization.service';
import { TypeOrganization } from 'src/Models/typeOrganization';
import { AccessService } from 'src/services/access.service';
import { Router } from '@angular/router';
import { CnpjValidator } from 'src/api/base/user/validators/cnpj.validator';
import { CnpjService } from 'src/services/cnpj.service';

@Component({
  selector: 'app-Organization',
  templateUrl: './Organization.component.html',
  styleUrls: ['./Organization.component.css']
})
export class OrganizationComponent implements OnInit {
organizationForm!: FormGroup;
  organizationUser: any;
  typeOrganizations: any;
  isOrganizationOwner = false;

  revenueCategories = [
    {
      groupName: 'Microempresa (ME)',
      options: [
        { value: 'me_less_360k', displayValue: 'Receita bruta anual até R$ 360 mil' },
      ]
    },
    {
      groupName: 'Empresa de Pequeno Porte (EPP)',
      options: [
        { value: 'epp_360k_to_4_8m', displayValue: 'Receita bruta anual superior a R$ 360 mil e igual ou inferior a R$ 4,8 milhões' },
      ]
    },
    {
      groupName: 'Empresa de Médio Porte',	
      options: [
        { value: 'med_4_8m_to_300m', displayValue: 'Receita bruta anual superior a R$ 4,8 milhões e igual ou inferior a R$ 300 milhões' },
      ]
    },
    {
      groupName: 'Empresa de Grande Porte',
      options: [
        { value: 'big_more_300m', displayValue: 'Receita bruta anual superior a R$ 300 milhões' },
      ]
    }
  ];
label: string = 'Empresa';
  user: any;
  isLoading = false;
  //ehStartUp = false;
  defaultOrganization!: Organization;
  constructor(private fb: FormBuilder,
    private _organization: OrganizationService,
    private _typeOrganization: TypeOrganizationService,
    private _access: AccessService,
    private _cnpjService: CnpjService,
    private _router: Router,
    private _dialog: DialogService) {
     
     }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    let isProfileComplete = this._access.checkIfProfileIsComplete();
    if(!isProfileComplete){
      this._dialog.openDialog('Perfil Incompleto', 'Complete seu perfil para acessar essa funcionalidade', 'warning');
      this._router.navigateByUrl('dashboard');
    } else {
    this.user = JSON.parse(localStorage.getItem('app.user')!);
    this.organizationUser = JSON.parse(localStorage.getItem('organizationUser')!);

    this.checkIfPersonIsOwner();
    this.initializeForm();
    this.fetchDefaultOrganization();
    this.getOrganization();
  }
}

  checkIfPersonIsOwner() {
    //check if it is owner through verification of claims
    this.isOrganizationOwner = this._access.checkIfItsOwner();
    if(this.isOrganizationOwner){
      //fetch data for formData from organization
      this._organization.getOrganizationByResponsibleEmail(this.user.email).subscribe(
        (result: Organization) => {
          this.organizationForm.get('typeId')?.setValue(result.typeOrganizationId);
          this.organizationForm.get('name')?.setValue(result.name);
          this.organizationForm.get('briefDescription')?.setValue(result.briefDescription);
          this.organizationForm.get('foundationYear')?.setValue(result.foundationYear);
          this.organizationForm.get('revenue')?.setValue(result.revenue);
          this.organizationForm.get('email')?.setValue(result.email);
          this.organizationForm.get('phoneNumber')?.setValue(result.phoneNumber);
          this.organizationForm.get('cnpj')?.setValue(result.cnpj);
          this.organizationForm.get('address')?.setValue(result.address);
          this.organizationForm.get('field')?.setValue(result.field);
          this.organizationForm.get('presentation')?.setValue(result.urlPresentationResume);
          this.organizationForm.get('ehStartup')?.setValue(result.ehStartup);
         } );
        }
  }

  initializeForm() {
    
    this.organizationForm = this.fb.group({
      typeId: ['', Validators.required],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      briefDescription: ['', Validators.required],
      foundationYear: ['', Validators.required],
      revenue: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      cnpj: ['', [Validators.required, CnpjValidator.validate()]],
      address: ['', Validators.required],
      ehStartup: ['', Validators.required],
      presentation: ['', Validators.required],
      field: ['', Validators.required],
    });
    
  }

  // trigger fulfillment of CNPJ data	in the form just for the first time and if the field is not complete

  ngAfterViewInit() {
    this.fulfillCnpjData();
  }


  fulfillCnpjData() {
    this.organizationForm.get('cnpj')?.valueChanges.subscribe(value =>{
if(value && value.length === 14){
        //Fetch data from the CNPJ API
        this._cnpjService.fetchCnpjData(value)
        .subscribe((data: any) => {
          this.organizationForm.patchValue({
            name: data.nome,
            email: data.email,
            phoneNumber: data.telefone,
            foundationYear: data.abertura,
            address: data.logradouro + ', ' + data.numero + ' - ' + data.bairro + ', ' + data.municipio + ' - ' + data.uf,
            field: data.atividade_principal[0].text
          });
        });
      }
    });
  }
  

  fetchDefaultOrganization() {
    this._organization.getDefaultOrganization().subscribe(
      (result: Organization) => {
        this.defaultOrganization = result;
      });
    }


  getOrganization() {
    this._typeOrganization.getAll().subscribe(
      (result: TypeOrganization[]) => {
        // Group typeOrganizations by classification
        const classificationGroups: any = result.reduce((acc: any, type: any) => {
          if (!acc[type.classification]) {
            acc[type.classification] = [];
          }
          acc[type.classification].push(type);
          return acc;
        }, {});

        // Further group by subClass within each classification
        this.typeOrganizations = Object.keys(classificationGroups).map(classification => {
          const subClassGroups = classificationGroups[classification].reduce((acc: any, type: any) => {
            const subClass = type.subClass || 'Main';
            if (!acc[subClass]) {
              acc[subClass] = [];
            }
            acc[subClass].push(type);
            return acc;
          }, {});

          return {
            classification: classification,
            subClasses: Object.keys(subClassGroups).map(subClassName => {
              return { name: subClassName, types: subClassGroups[subClassName] };
            }).filter(subGroup => subGroup.name !== 'Main' || subGroup.types.length > 1) // Filter out 'Main' if it's the only group
          };
        });

        // Sort each subClass group by name
        this.typeOrganizations.forEach((group: any) => {
          group.subClasses.forEach((subGroup: any) => {
            subGroup.types.sort((a: any, b: any) => a.name.localeCompare(b.name));
          });
        });
      },
      error => {
        // Handle error
        console.error('Error fetching type organizations', error);
      }
    );
  }

  addAdmin(){
    this._dialog.openAddOrganizationAdmin(this.organizationUser.organizationId);

  }
  
  


  onSubmit() {
    let isProfileComplete = this._access.checkIfProfileIsComplete();
    if (!isProfileComplete) {
      this._dialog.openDialog('Erro', 'Para esta operação é preciso completar seu perfil', 'error');
    } else {
      this.isLoading = true;
      const organization = <Organization>{}; // Type assertion
      organization.typeOrganizationId = this.organizationForm.get('typeId')?.value;
      organization.name = this.organizationForm.get('name')?.value;
      organization.briefDescription = this.organizationForm.get('briefDescription')?.value;
      organization.foundationYear = this.organizationForm.get('foundationYear')?.value;
      organization.revenue = this.organizationForm.get('revenue')?.value;
      organization.email = this.organizationForm.get('email')?.value;
      organization.phoneNumber = this.organizationForm.get('phoneNumber')?.value;
      organization.cnpj = this.organizationForm.get('cnpj')?.value;
      organization.address = this.organizationForm.get('address')?.value;
      organization.field = this.organizationForm.get('field')?.value;
      organization.urlPresentationResume = this.organizationForm.get('presentation')?.value;
      organization.responsavelInclusao = this.user.id;
      const ehStartup = this.organizationForm.get('ehStartup')?.value;
      organization.ehStartup = ehStartup === 'true' ? true : false;
      organization.responsavelInclusao = this.user.nome;
      organization.responsavelInclusaoEmail = this.user.email;
      organization.dataDoCadastro = new Date();
      organization.status = 'Pendente';
      
      this._organization.addOrganization(organization).subscribe(
        (result) => {
          console.log(result);
          this._dialog.openDialog('Sucesso', 'Sua solicitação foi recebida com sucesso', 'success');
          // Reset form
          this.organizationForm.reset();
          this.isLoading = false;
        },
        (error) => {
          // Handle error response
          this.isLoading = false;
          console.error(error);
          this._dialog.openDialog('Erro', error, 'error');
        }
      );
    }
  }
  
}
