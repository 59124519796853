<app-NavBar></app-NavBar>
<div class="container">
  <!-- create four cards for four link options -->
  <div class="row mb-3 mt-3">
    <!-- Card for Person -->
    <div class="col">
      <div class="card shadow" style="width: 35rem;">
        <img src="/assets/personalPic.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">Profile</h5>
          <p class="card-text">Complete your profile</p>
          <a href="person" class="btn btn-primary">Profile</a>
        </div>
      </div>
    </div>
    
  </div>

    <!-- Card for Organization -->
    <div class="row mb-3 mt-3">

      <div class="col">
        <div class="card shadow" style="width: 35rem;">
          <img src="/assets/organizationPic.jpg
          " class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Organization</h5>
            <p class="card-text">What do you think about registering your company with us?</p>
            <a href="organization" class="btn btn-primary">Include Organization</a>
          </div>
        </div>
      </div>
    </div>


    <div class="row mb-3 mt-3">
    <!-- Card for Problem -->
    <div class="col">
      <div class="card shadow" style="width: 35rem;">
        <img src="/assets/problemPic.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">Problem</h5>
          <p class="card-text">Insert a new problem to the database.</p>
          <a href="problem" class="btn btn-primary">Insert Problem</a>
        </div>
      </div>
    </div>
  </div>

    <!-- Card for Solution -->
    <div class="row mb-3 mt-3">
      <div class="col">
        <div class="card shadow" style="width: 35rem;">
          <img src="/assets/solutionPackage.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">Solution</h5>
            <p class="card-text">Insert a new solution to the database.</p>
            <a href="solution" class="btn btn-primary">Insert Solution</a>
          </div>        
        </div>
      </div>
      
    </div>
</div>
