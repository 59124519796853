import { Problem } from 'src/Models/problem';
import { Solution } from 'src/Models/solution';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-DialogCompare',
  templateUrl: './DialogCompare.component.html',
  styleUrls: ['./DialogCompare.component.css']
})
export class DialogCompareComponent implements OnInit {
  proposal: any;
  solution: Solution = {} as Solution;
  problem: Problem = {} as Problem;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.problem = this.data.challenge;
    this.solution = this.data.solution;

  }



}
