<h1 mat-dialog-title>Reset de Senha</h1>
<div mat-dialog-content>
  Insira o email para o reset da senha:
  <form [formGroup]="form">
    <mat-form-field>
      <input  matInput type="email" formControlName="email" placeholder="Email">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Não</button>
  <button mat-button mat-dialog-close cdkFocusInitial [disabled]="!form.valid" (click)="confirmationReset()">Sim</button>
</div>