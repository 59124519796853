import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChangeViewModel } from '../changeViewModel';
import { UserService } from '../user.service';
import { createPasswordStrengthValidator } from '../validators/password-strength.validators';

@Component({
  selector: 'app-changePassword',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.css']
})
export class ChangePasswordComponent implements OnInit {
  hidePassword = true;
  hideConfirmPassword = true;
  formData!: FormGroup;
  userLogado: any;
  thereIsUser: boolean = false;

  errors: any[] = [];
  constructor(private fb: FormBuilder,
    private _userService: UserService,
    private _toastService: ToastrService,
    private _spinner: NgxSpinnerService,
  private _router: Router) { }

  ngOnInit() {
    //check if user is logged in
    this.userLogado = this._userService.obterUsuario();
    if (Object.keys(this.userLogado).length > 0) {
      this.thereIsUser = true;
    }

    this.formData = this.fb.group({
      email: [this.userLogado !== null ? this.userLogado.email : '' , Validators.required],
      oldPassword: ['', Validators.required],
      newPassword: ['',
      [
        Validators.required,
        Validators.min(8),
        createPasswordStrengthValidator(),
        ],
      ],
      confirmPassword: ['', [Validators.required]],
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword')?.value === g.get('confirmPassword')?.value
      ? null : { mismatch: true };
  }

  changePass() {
    this._spinner.show();
    const user = <ChangeViewModel>{};
    user.email = this.formData.value.email;
    user.oldPassword = this.formData.value.oldPassword;
    user.newPassword = this.formData.value.newPassword;
    this._userService.changePass(user)
      .subscribe({
        next: (result) => this.onSaveComplete(result),
        error: (e) => this.onError(e),
      })
  }

  onSaveComplete(response: any) {
    
    this._userService.persistirUserApp(response);
    this._toastService.success('Sua senha foi alterada com sucesso');
    this._router.navigateByUrl('/');
    this._spinner.hide();
  }

  onError(fail: any) {
    this.errors = fail.error.errors || [fail.error.message] || ['Ocorreu um erro inesperado'];
    this._spinner.hide();

    if (this.errors.includes('Password alterado com sucesso!')) {
      this._toastService.success('Sua senha foi alterada com sucesso');
      this._router.navigateByUrl('/');
    } else {
      this.errors.forEach((error: string) => {
        this._toastService.error(error, 'Erro');
      });
    }
  }

  get email() {
    return this.formData.controls['email'];
  }

  get newPassword() {
    return this.formData.controls['newPassword'];
  }

  get confirmPassword() {
    return this.formData.controls['confirmPassword'];
  }

}
