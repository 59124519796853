import { ToastrService } from 'ngx-toastr';
import { IdentityRoleService } from './../../../services/identityRole.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Role } from 'src/Models/role';
import { DialogService } from 'src/services/dialog.service';
import { RoleService } from 'src/services/role.service';
import { UserRoleService } from 'src/services/userRole.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-Users',
  templateUrl: './Users.component.html',
  styleUrls: ['./Users.component.css']
})
export class UsersComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  roles!: Role[]; // Using the Role interface
  users: any;
  usersDataSource = new MatTableDataSource<any>();
  usersWithRoles!: any[];
  usersWithoutRoles!: any[];
  displayedColumns: string[] =
    [
      'name',
      'email',
      'roles',
      'actions'
    ];

  constructor(private roleService: RoleService,
    private _userRole: UserRoleService,
    private _identityRole: IdentityRoleService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    private _toastr: ToastrService,
  private _dialog: DialogService) { }

  ngOnInit() {
    this.subscription.add(this._dialog.onUpdatedFinished.subscribe(() => {
      this.getUsersandRoles();
    }));
    this.getRoles();
    this.getUsersandRoles();
  }

  getUsersandRoles() {
    this._spinner.show();
  
    this.roleService.getUserRoles().subscribe({
      next: (response) => {
        this.users = response
        this.usersDataSource = new MatTableDataSource(this.users);
          this._spinner.hide();
      },
      error: (error) => {
        console.error('Error fetching users and roles:', error);
        this._spinner.hide();
      }
    });
  }
  
  
  

  getRoles() {
    this.roleService.getRoles().subscribe({
      next: (roles: Role[]) => {
        this.roles = roles;
      },
      error: (error: any) => {
        // Handle errors
        console.error('Error fetching roles:', error);
      }
    });
  }

  assignRole(userId: string, roleId: string) {
    this.roles = this.roles.filter((role: { id: any; }) => role.id !== roleId);
    this._dialog.openAssignRoleDialog(userId, this.roles);
    
  }
    

  
  unassignRole(userId: string, roleId: any) {
    const roles = roleId.roles[0];
    const data = {
      userId: userId,
      roleId: roles,
    };
    // user confirmationDialog to confirm the role unassignment
    this._dialog.confirmDialog('Remover Permissão', 'Tem certeza que deseja remover a permissão?').subscribe({
      next: (result: any) => {
        if (result) {
          this._userRole.unassignUserRole(data).subscribe({
            next: (response) => {
              console.log('Role unassigned successfully:', response);
              // Find user and remove role from their roles array
              this.getUsersandRoles();
              // Optionally provide feedback or reset UI elements
            },
            error: (error) => {
              if (error.error && error.error.includes("SingleUser")){
                // Display a message to the user that they cannot remove their own role
                this._toastr.error('Você não pode remover seu próprio papel');
              } else {
              console.error('Error unassigning role:', error);
              // Provide error feedback
              }
            }
          });
        }
      }
    });
  }

  doFilter(filterValue: any) {
    filterValue = filterValue.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.usersDataSource.filter = filterValue;
  }

  openDialogRole() {
    // Open the dialog with a text input for the role name
    this._dialog.openAddRoleDialog();
    
  }

  openDialogUser() {
    this._router.navigate(['/novoUsuario']);
  }
   
  
  }