import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormationAreaService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

    public addFormationArea(formationArea: any): Observable<any> {
      return this.http.post<any>(`${this.baseUrl}api/formationAreas`, formationArea);
    }

    public getAll(): Observable<any[]> {
      return this.http.get<any[]>(`${this.baseUrl}api/formationAreas`);
    }

    public getById(id: number): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}api/formationAreas/${id}`);
    }

    public updateFormationArea(formationArea: any, id: number): Observable<any> {
      return this.http.put<any>(this.baseUrl + 'api/formationAreas/' + id, formationArea);
    }

    public deleteFormationArea(id: number): Observable<any> {
      return this.http.delete<any>(this.baseUrl + 'api/formationAreas/' + id);
    }

}
