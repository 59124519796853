import { UserRoleAssignmentModel } from 'src/Models/UserRoleAssignmentModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/api/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService extends BaseService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    super()
  }

  getUserRoles(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/userRoles');
  }

  createUserRole(roleName: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/userRoles', roleName);
  }

  assignUserRole(data: UserRoleAssignmentModel): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/userRoles/assign', data, super.ObterAuthHeaderJson());
  }

  unassignUserRole(data: UserRoleAssignmentModel): Observable<any> {
    const options = {
      headers: super.ObterAuthHeaderJson().headers,
      body: data
    }

    return this.http.delete<any>(`${this.baseUrl}api/userRoles/unassign`, options);
}


  deleteUserRole(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl + 'api/userRoles'}/${id}`);
  }

  updateUserRole(id: string, newRoleName: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl + 'api/userRoles'}/${id}`, newRoleName, super.ObterAuthHeaderJson());
  }

  checkIfEmailExists(email: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/userRoles/emailExists/${email}`, super.ObterAuthHeaderJson());
  }
}