import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/api/base/user/user.service';
import { AccessService } from 'src/services/access.service';

@Component({
  selector: 'app-Home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.css']
})
export class HomeComponent implements OnInit {
  isMaster: boolean = false;
  constructor(
    private _access: AccessService,
    private _user: UserService
  ) { }

  ngOnInit() {
        //check if the user contains the role Master

  }

}
