import { AddOrganizationAdminComponent } from './Organization/AddOrganizationAdmin/AddOrganizationAdmin.component';
import { AssignAdminToOrganizationComponent } from './shared/AssignAdminToOrganization/AssignAdminToOrganization.component';
import { AddRoleDialogComponent } from './../api/base/user/AddRoleDialog/AddRoleDialog.component';
import { HttpErrorInterceptorService } from './../services/http-error.service';
import { EmailConfirmationComponent } from './../api/base/user/emailConfirmation/emailConfirmation.component';
import { ResetPasswordComponent } from './../api/base/user/resetPassword/resetPassword.component';
import { AssignDialogRoleComponent } from './shared/AssignDialogRole/AssignDialogRole.component';
import { UserRoleService } from './../services/userRole.service';
import { RoleService } from './../services/role.service';
import { UsersComponent } from './Administration/Users/Users.component';
import { MatTableResponsiveDirective } from './shared/mat-table-responsive/mat-table-responsive.directive';
import { DialogCompareComponent } from './shared/DialogCompare/DialogCompare.component';
import { ConfirmDialogComponent } from './shared/ConfirmDialog/ConfirmDialog.component';
import { AccessService } from './../services/access.service';
import { InfoTableDialogComponent } from './shared/InfoTableDialog/InfoTableDialog.component';
import { ReceivedProposalsComponent } from './Proposal/ReceivedProposals/ReceivedProposals.component';
import { MySolutionsComponent } from './Solution/MySolutions/MySolutions.component';
import { MyChallengesComponent } from './Problem/MyChallenges/MyChallenges.component';
import { FileUploadComponent } from './shared/FileUpload/FileUpload.component';
import { MyProposalsComponent } from './Proposal/MyProposals/MyProposals.component';
import { DialogProposalComponent } from './Proposal/DialogProposal/DialogProposal.component';
import { ApproveOrganizationComponent } from './Administration/ApproveOrganization/ApproveOrganization.component';
import { NotificationDialogComponent } from './shared/NotificationDialog/NotificationDialog.component';
import { SearchTableSolutionComponent } from './Solution/SearchTableSolution/SearchTableSolution.component';
import { SearchTableChallengeComponent } from './Problem/SearchTableChallenge/SearchTableChallenge.component';

import { UserService } from './../api/base/user/user.service';
import { User } from './../api/base/user/user';
import { MenuUserComponent } from './../api/base/user/menuUser/menuUser.component';
import { LoginComponent } from './../api/base/login/login.component';
import { NewUserComponent } from './../api/base/user/newUser/newUser.component';
import { InsertSolutionComponent } from './Solution/InsertSolution/InsertSolution.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PersonFormComponent } from './PersonForm/PersonForm.component';
import { OrganizationComponent } from './Organization/Organization.component';
import { environment } from 'src/environments/environment';
import { ProblemComponent } from './Problem/Problem.component';
import { SolutionComponent } from './Solution/Solution.component';
import { InsertProblemComponent } from './Problem/InsertProblem/InsertProblem.component';
import { PersonaComponent } from './Persona/Persona.component';
import { HomeComponent } from './Home/Home.component';
import { NavBarComponent } from './NavBar/NavBar.component';
import { ChangePasswordComponent } from 'src/api/base/user/changePassword/changePassword.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DashboardComponent } from './Dashboard/Dashboard.component';
import { PersonalNavComponent } from './PersonalNav/PersonalNav.component';
import { DashboardService } from 'src/services/dashboard.service';
import { ProposalComponent } from './Proposal/Proposal.component';
import { DialogService } from 'src/services/dialog.service';
import { AdministrationComponent } from './Administration/Administration.component';
import { DialogMyProposalComponent } from './Proposal/MyProposals/dialogMyProposal/dialogMyProposal.component';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FileUploadService } from 'src/services/fileUpload.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faHome, faLock, faSignInAlt, faSignOutAlt, faUser, faUserCheck, faUserCog, faUserEdit, faUserMinus, faUserPlus, faUserSlash, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { TypeOrganizationDialogComponent } from './shared/TypeOrganizationDialog/TypeOrganizationDialog.component';

library.add(faEye, faEyeSlash, faUser, faLock, faUserPlus, faSignInAlt, faSignOutAlt, faHome, faUserCog, faUserCheck, faUserTimes, faUserEdit, faUserMinus, faUserSlash)




export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [											
    AppComponent,
      PersonFormComponent,
      OrganizationComponent,
      ProblemComponent,
      SolutionComponent,
      InsertProblemComponent,
      InsertSolutionComponent,
      PersonaComponent,
      HomeComponent,
      NavBarComponent,
      ChangePasswordComponent,
      NewUserComponent,
      LoginComponent,
      MenuUserComponent,
      DashboardComponent,
      PersonalNavComponent,
      SearchTableChallengeComponent,
      SearchTableSolutionComponent,
      ProposalComponent,
      NotificationDialogComponent,
      AdministrationComponent,
      ApproveOrganizationComponent,
      DialogProposalComponent,
      AddRoleDialogComponent,
      MyProposalsComponent,
      DialogMyProposalComponent,
      FileUploadComponent,
      MyChallengesComponent,
      MySolutionsComponent,
      ReceivedProposalsComponent,
      InfoTableDialogComponent,
      ConfirmDialogComponent,
      DialogCompareComponent,
      MatTableResponsiveDirective,
      UsersComponent,
      AssignDialogRoleComponent,
      ResetPasswordComponent,
      EmailConfirmationComponent,
      AssignAdminToOrganizationComponent,
      AddOrganizationAdminComponent,
      TypeOrganizationDialogComponent
   ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    FontAwesomeModule,
    FormsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatGridListModule,
    MatCardModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatDatepickerModule,
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: 'BASE_URL',
      useValue: environment.root,
    },
    { provide: MAT_DATE_FORMATS, 
      useValue: MY_DATE_FORMATS 
    },
    { provide: MAT_DATE_LOCALE, 
      useValue: 'pt-BR' 
    },
    provideNgxMask(maskConfig),
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    DashboardService,
    ToastrService,
    DialogService,
    FileUploadService,
    AccessService,
    RoleService,
    UserRoleService,
    NgxSpinnerService
    

  ],
  exports: [MatTableResponsiveDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
