import { ChangeViewModel } from './changeViewModel';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { User } from './user';
import { BaseService } from '../base.service';
import { Router } from "@angular/router";


@Injectable()
export class UserService extends BaseService {
  helper = new JwtHelperService();
    constructor(private http: HttpClient,
      private router: Router) { super() }

    login(user: User): Observable<User> {
        return this.http
            .post(this.UrlService + 'auth/entrar', user, super.ObterHeaderJson())
            .pipe(
                map(super.extractData),
                catchError(super.serviceError)
            );
    }

  cadastrar(user: User): Observable<User> {
      return this.http.post<User>(this.UrlService + 'auth/nova-conta', user);
  }
  
  changePass(changeView: ChangeViewModel): Observable<ChangeViewModel>{
    return this.http.post<ChangeViewModel>(this.UrlService + 'auth/change', changeView);
  }

  resetPass(email: string): Observable<any> {
    const params = new HttpParams().set('email', email);
    return this.http.post<any>(this.UrlService + 'auth/reset', null, { params: params });
  }

  confirmEmail(userId: string, code: string): Observable<any> {
    const params = new HttpParams().set('userId', userId).set('code', code);
    return this.http.get<any>(this.UrlService + 'auth/confirmar-email', { params })
      .pipe(
        map(super.extractData),
        catchError(super.serviceError)
      );
  }



    logout(){
      localStorage.removeItem('app.token');
      localStorage.removeItem('app.user');
      this.router.navigateByUrl('/');
    }

    persistirUserApp(response: any){
        localStorage.setItem('app.token', response.accessToken);
        localStorage.setItem('app.user', JSON.stringify(response.userToken));
    }

    checkEmailExists(email: string): boolean {
        //check in local storage if email exists
        let user = JSON.parse(localStorage.getItem('app.user') || '{}');
        if (user.email === email) {
            return true;
        }
        return false;
    }

    

}