import { AccessService } from 'src/services/access.service';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'app-Administration',
  templateUrl: './Administration.component.html',
  styleUrls: ['./Administration.component.css'],
  providers: [MatIconModule]
})
export class AdministrationComponent implements OnInit {

  tiles = [
    {id: 1, text: 'Administração', cols: 2, rows: 1, color: 'lightblue', icon: 'admin_panel_settings', content: 'Administração de usuários, organizações e propostas.'},
    {id: 2, text: 'Usuários', cols: 2, rows: 1, color: 'lightgreen', icon: 'people', content: 'Administração de usuários.'},
    {id: 3, text: 'Organizações', cols: 2, rows: 1, color: 'lightpink', icon: 'business', content: 'Administração de organizações.'},
    {id: 4, text: 'Propostas', cols: 2, rows: 1, color: '#DDBDF1', icon: 'description', content: 'Administração de Propostas.'},
  ];
  grid = {cols: 4, rowHeight: '2:1', gutter: '10px'};

  ehAdmin = false;

  constructor(private breakpointObserver: BreakpointObserver,
    private _access: AccessService,
    private router: Router) {}

  ngOnInit() {
    this._access.checkRights(this.ehAdmin);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.grid.cols = 1;
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.grid.cols = 2;
        } else if (result.breakpoints[Breakpoints.Medium] || result.breakpoints[Breakpoints.Large] || result.breakpoints[Breakpoints.XLarge]) {
          this.grid.cols = 4;
        }
        this.grid.rowHeight = '2:2';
      }
    });
  }

  viewTile(tileId: number | string) {
    // Implement the view logic here. For example, navigating to a detailed view page:
    //if click in usuarios send to UserComponent.html
    switch (tileId){
      case 'data':
        console.log(tileId);
        break;
      case 2:
        this.router.navigateByUrl('users');
        break;
      case 3:
        this.router.navigate(['/view', tileId]);
        break;
        default:
          console.log('There is no place to go!');
          break;
    }
  }
}
