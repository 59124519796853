<h1 mat-dialog-title>Assign User</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="email" (blur)="validateEmail()" placeholder="Enter email" />
    <mat-error *ngIf="emailExists === false">Email not found in user base</mat-error>
    <mat-error *ngIf="emailExists === true">Email found in user base</mat-error>
    <mat-error *ngIf="emailInvalid">Invalid email format</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="checkEmail()" [disabled]="!email" cdkFocusInitial>Assign</button>
</div>
