import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-email-confirmation',
  template: `
    <div *ngIf="message" class="confirmation-message">
      {{ message }}
    </div>
  `,
  styles: [`
    .confirmation-message {
      font-size: 1.2em;
      color: green;
    }
  `]
})
export class EmailConfirmationComponent implements OnInit {
  message: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params); // Debugging: Log the params to verify
      const userId = params['userId'];
      const code = params['code'];
      const status = params['status'];

      if (status) {
        switch (status) {
          case 'success':
            this.message = 'E-mail confirmado com sucesso.';
            break;
          case 'invalid':
            this.message = 'Dados inválidos.';
            break;
          case 'notfound':
            this.message = 'Usuário não encontrado.';
            break;
          case 'alreadyconfirmed':
            this.message = 'O e-mail já está confirmado.';
            break;
          case 'roleerror':
            this.message = 'Erro ao adicionar o usuário à role.';
            break;
          case 'error':
            this.message = 'Não foi possível confirmar o e-mail.';
            break;
          case 'servererror':
            this.message = 'Ocorreu um erro interno no servidor.';
            break;
          default:
            this.message = 'Erro desconhecido.';
            break;
        }
      } else if (userId && code) {
        this.userService.confirmEmail(userId, code).subscribe(
          response => {
            this.message = response;
          },
          error => {
            this.message = error.error;
          }
        );
      } else {
        this.message = 'Invalid parameters.';
      }
    });
  }
}
