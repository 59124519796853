import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TypeOrganization } from 'src/Models/typeOrganization';

@Injectable({
  providedIn: 'root'
})
export class TypeOrganizationService {

constructor(private http: HttpClient,
  private activatedRoute: ActivatedRoute,
  @Inject('BASE_URL') private baseUrl: string) { }

  public addTypeOrganization(typeOrganization: any): Observable<TypeOrganization> {
    return this.http.post<TypeOrganization>(`${this.baseUrl}api/typeOrganization`, typeOrganization);
  }

  public getAll(): Observable<TypeOrganization[]> {
    return this.http.get<TypeOrganization[]>(`${this.baseUrl}api/typeOrganization`);
  }

  public getById(id: number): Observable<TypeOrganization> {
    return this.http.get<TypeOrganization>(`${this.baseUrl}api/typeOrganization/${id}`);
  }

  public updateTypeOrganization(typeOrganization: any, id: number): Observable<TypeOrganization> {
    return this.http.put<TypeOrganization>(this.baseUrl + 'api/typeOrganization/' + id, typeOrganization);
  }

  public deleteTypeOrganization(id: number): Observable<TypeOrganization> {
    return this.http.delete<TypeOrganization>(this.baseUrl + 'api/typeOrganization/' + id);
  }

}
