<app-NavBar></app-NavBar>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
<div class="container rounded" >
  <div class="mb-4 mt-4">
    <h4 style="text-align:center;" >Preencha os campos abaixo para alterar sua senha</h4>
  </div>
<form [formGroup]="formData">


<div class="row">
  <div class="col">

  <mat-form-field style="width: 100%;">
  <input name="email" type="email" matInput formControlName="email" placeholder="Email"
    required>
  <mat-error *ngIf="email.invalid"
      class="alert alert-danger">
    <div *ngIf="email?.errors?.['required']">
      Email is required.
    </div>
  </mat-error>
</mat-form-field>
</div>
</div>

<div class="row">
  <div class="col">
    <mat-form-field style="width: 100%;">
  <input name="oldPassword"
        [type]="hidePassword ? 'password' : 'text'"
         matInput
         formControlName="oldPassword"
         [placeholder]="thereIsUser ? 'Senha Atual' : 'Senha Provisória'"
         required>
         <!-- <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button">
          <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button> -->

    
    </mat-form-field>
  </div>
</div>


<div class="row">
  <div class="col">
    <mat-form-field style="width: 100%;">
      <input [type]="hidePassword ? 'password' : 'text'"
             matInput
             placeholder="Nova senha"
             formControlName="newPassword"
             required>
      <!-- <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button">
        <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button> -->
      <mat-error *ngIf="newPassword.errors">
        Sua senha deve conter letras minúsculas, maiúsculas e caracteres numéricos.
      </mat-error>
    </mat-form-field>
  </div>
</div>

<!-- confirm password -->
<div class="row">
  <div class="col">
    <mat-form-field style="width: 100%;">
      <input [type]="hideConfirmPassword ? 'password' : 'text'"
             matInput
             placeholder="Confirme a nova senha"
             formControlName="confirmPassword"
             required>
      <!-- <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" type="button">
        <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button> -->
      <mat-error *ngIf="formData.errors?.['passwordMismatch']">
        As senhas não conferem.
      </mat-error>
    </mat-form-field>
  </div>
</div>


<button [disabled]="!formData.valid" mat-raised-button (click)="changePass()" type="submit"   >Enviar</button>
</form>
</div>