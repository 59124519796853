<div class="container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Carregando... </p>
  </ngx-spinner>
  <h1>Seja bem-vindo!</h1>
  <h4>Entre na sua comunidade de inovação aplicada!</h4>
  <img src="assets/inowaLabelW.png" alt="Inowa Lab">

  <form [formGroup]="form">
    <div class="form-group">
      <input type="email" class="form-control" formControlName="email" id="email" placeholder="Email">
    </div>

    <div class="form-group input-group">
      <input [type]="hidePassword ? 'password' : 'text'" class="form-control" formControlName="password" id="password" placeholder="Senha">
      <div class="input-group-append">
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button">
          <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </div>
    </div>
    <button type="submit" class="btn btn-primary" (click)="login()">Login</button>
  </form>

  <!-- forgot password -->
  <a style="cursor: pointer; color:rgb(25, 127, 230);" (click)="resetDeSenha()">Esqueceu a senha?</a>

  <hr>
  <p><b>Não possui uma conta?</b></p>
  <a routerLink="/getStarted">Inscreva-se</a>
  <div class="whatsapp-contact" style="text-align:end;">
    <a href="https://wa.me/5521998771500" target="_blank" rel="noopener noreferrer">
      <img src="assets/whatsapp.png" style="width:50px;" alt="WhatsApp Icon">
    </a>
  </div>
</div>
