import { DialogService } from './../../../services/dialog.service';
import { SolutionService } from './../../../services/solution.service';
import { ProposalService } from './../../../services/proposal.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/api/base/user/user.service';
import { ProblemService } from 'src/services/problem.service';
import { AccessService } from 'src/services/access.service';

@Component({
  selector: 'app-ReceivedProposals',
  templateUrl: './ReceivedProposals.component.html',
  styleUrls: ['./ReceivedProposals.component.css']
})
export class ReceivedProposalsComponent implements OnInit {

  statusOptions = ['Entregue', 'Em avaliação', 'Aprovada', 'Em contratação', 'Reprovada'];

  displayedColumns: string[] = [
    'proposalId', 'organization', 'ownerName', 'challengeField', 
    'theme', 'description', 
     'trl', 'availableFees', 
    'status'
  ];

  proposals: any;
  solutionsInTable = new MatTableDataSource<any>();
  user: any;
  selectedChallenge: any;
  challenges: any;
  solutions: any;

  constructor(private _proposals: ProposalService,
    private _problem: ProblemService,
    private _dialog: DialogService,
    private _access: AccessService,
    private _user: UserService) {}

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.user = this._user.obterUsuario();
    // Implement your logic here
    this.getData();
  }

  getData(){
    this._proposals.getProposalReceived(this.user.id).subscribe(
      (data: any) => {
        this.proposals = new MatTableDataSource(data);
        this.solutions = data.map((p: { solution: any; }) => p.solution);
        this.challenges = data.map((p: { problem: any; }) => p.problem);
      }
    );
  }

  updateStatus(element: any, target: any) {
    // Update the status of the element, might need to update the data source or server
    if (target instanceof HTMLSelectElement) {
      const newStatus = target.value;
      element.status = newStatus;
      // Implement the logic to update this status in your data source or backend
    }
    // Implement the logic to update this status in your data source or backend
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // Implement your filtering logic here
  }

  getSolutionByChallenge(data: any) {
    let challengeId = data.value.problemId;
    this._proposals.getProposalByChallenge(challengeId)
    .subscribe(result => {
      this.solutionsInTable = new MatTableDataSource<any>(result.map((p: { solution: any; }) => p.solution));
    })
  }

  openDialog(solution: any, challenge: any) {
    // Implement your dialog opening logic here
    this._dialog.compareDialog(solution, challenge);
  }
}

