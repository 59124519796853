import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {

  constructor(private toastr: ToastrService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        debugger;
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Client-side error: ${error.error.message}`;
          this.toastr.error(errorMessage, 'Error');
        } else {
          // Server-side errors
          errorMessage = `Erro no servidor: ${error.status} ${error.message}`;
          if (error.status === 400 && error.error.errors) {
            // Validation errors
            const validationErrors = error.error.errors;
            for (const field in validationErrors) {
              if (validationErrors.hasOwnProperty(field)) {
                validationErrors[field].forEach((message: string) => {
                  this.toastr.error(message, `Validation error: ${field}`);
                });
              }
            }
          } else {
            switch (error.status) {
              case 401:
                this.toastr.error('Unauthorized', 'Error 401');
                this.router.navigate(['/login']);
                break;
              case 404:
                this.toastr.error('Resource not found', 'Error 404');
                this.router.navigate(['/not-found']);
                break;
              case 409:
                this.toastr.error(error.error, 'CNPJ já está cadastrado'); // Display conflict error message
                break;
              case 500:
                this.toastr.error('Internal server error', 'Error 500');
                break;
              case 0:
                this.toastr.error('Servidor temporariamente fora', 'Error 503');
                break;
              default:
                this.toastr.error(errorMessage, 'Error');
                break;
            }
          }
        }

        console.error(errorMessage);
        return throwError(errorMessage);
      })
    );
  }
}
