<h1 mat-dialog-title>Tornar {{label}}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Colaboradores</mat-label>
    <mat-select [(value)]="selectedColaborator">
      <mat-option *ngFor="let colaborator of colaborators" [value]="colaborator.personId">{{ colaborator.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="addNewPersonSolutionOrPersonOrganization()" cdkFocusInitial>Atribuir</button>
</div>