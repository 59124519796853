import { UserService } from 'src/api/base/user/user.service';
import { ProposalService } from './../../services/proposal.service';
import { Component, OnInit } from '@angular/core';
import { AccessService } from 'src/services/access.service';

@Component({
  selector: 'app-Proposal',
  templateUrl: './Proposal.component.html',
  styleUrls: ['./Proposal.component.css']
})
export class ProposalComponent implements OnInit {
  user: any;
  isThereProposal = false;
  isOrganizationOwner = false;
  constructor(private _proposal: ProposalService,
    private _access: AccessService,
    private _user: UserService) { }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.user = this._user.obterUsuario();
    // this.getData();
    this.checkIfPersonIsOwner();
  }

  checkIfPersonIsOwner() {
    this.isOrganizationOwner = this._access.checkIfItsOwner();
  }

  

}
