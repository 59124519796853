<app-PersonalNav></app-PersonalNav>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-scale-multiple" [fullScreen] = "true"><p style="color: white" > Carregando... </p></ngx-spinner>
<div class="container mw-100">
  <div class="row">
    <div class="col-md-12">
      <h1>Control Panel</h1>
      <h4>Welcome to the control panel. From here, you can manage user roles and permissions.</h4>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div fxLayout fxLayoutAlign="center " style="margin-top: 10px;">
        <button mat-raised-button color="primary" (click)="openDialogUser()">Add User</button>
      </div>
    </div>
    <div class="col">
      <div fxLayout fxLayoutAlign="center " style="margin-top: 10px;">
        <button mat-raised-button color="primary" (click)="openDialogRole()">Add Role</button>
      </div>
    </div>
    <div class="col">
      <div fxLayout fxLayoutAlign="center " style="margin-top: 10px;">
        <mat-form-field fxFlex="50%">
          <input matInput type="text" (keyup)="doFilter($event)" placeholder="Filtro">
        </mat-form-field>
      </div>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      How many users in InowaLab: {{usersDataSource.data.length}}
    </div>
  </div>


  <!-- dispose of the data in a MatTable -->
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="usersDataSource" matSort class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef > ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef > Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <!-- Weight Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef > Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      
      
      <!-- Symbol Column -->
      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef > Roles </th>
        <td mat-cell *matCellDef="let element"> 
          <p style="margin-right: 5px;">Roles: <span *ngFor="let role of element.roles">{{ role }}</span></p>  
        </td>
      </ng-container>

      <!-- ações -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="primary" style="margin: 3px" (click)="assignRole(element.id, element.roles.id)">Assign Role</button>
          <button mat-raised-button color="warn" (click)="unassignRole(element.id, element)">Unassign Role</button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>