import { BaseService } from 'src/api/base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentityRoleService extends BaseService{

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { 
    super()
  }

  getUserRoles(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/UserRoles/usersWithRoles', super.ObterAuthHeaderJson());
  }

  getIdentityRoles(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/identityRoles', super.ObterAuthHeaderJson());
  }

  createIdentityRole(roleName: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = { roleName };
    return this.http.post<any>(this.baseUrl + 'api/identityRoles', body, { headers });
  }
  

  deleteIdentityRole(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl + 'api/identityRoles'}/${id}`, super.ObterAuthHeaderJson());
  }

  updateIdentityRole(id: string, newRoleName: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl + 'api/identityRoles'}/${id}`, newRoleName, super.ObterAuthHeaderJson());
  }

}
