import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CnpjValidator {
  static validate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const cnpj = control.value;

      if (!cnpj) {
        return null;
      }

      // Remove caracteres não numéricos
      const cleanedCnpj = cnpj.replace(/[^\d]+/g, '');

      // Valida tamanho
      if (cleanedCnpj.length !== 14) {
        return { cnpjInvalid: true };
      }

      // Elimina CNPJs inválidos conhecidos
      if (/^(\d)\1+$/.test(cleanedCnpj)) {
        return { cnpjInvalid: true };
      }

      // Valida DVs
      let tamanho = cleanedCnpj.length - 2;
      let numeros = cleanedCnpj.substring(0, tamanho);
      const digitos = cleanedCnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number(digitos.charAt(0))) {
        return { cnpjInvalid: true };
      }

      tamanho += 1;
      numeros = cleanedCnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number(digitos.charAt(1))) {
        return { cnpjInvalid: true };
      }

      return null;
    };
  }
}
