import { AccessService } from 'src/services/access.service';
import { UserService } from './../../api/base/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Problem } from 'src/Models/problem';
import { DialogService } from 'src/services/dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-Problem',
  templateUrl: './Problem.component.html',
  styleUrls: ['./Problem.component.css']
})
export class ProblemComponent implements OnInit {
  isCompleted = false;
  isOrganizationOwner = false;
  user: any;
  constructor(private _user: UserService,
    private _access: AccessService,
    private _router: Router,
    private _dialog: DialogService) { }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.user = this._user.obterUsuario();
    let isProfileComplete = this._access.checkIfProfileIsComplete();
    if(!isProfileComplete){
      this._dialog.openDialog('Perfil Incompleto', 'Complete seu perfil para acessar essa funcionalidade', 'warning');
      this._router.navigateByUrl('dashboard');
    }

    this.checkIfPersonIsOwner();
  }

  checkIfPersonIsOwner() {
    this.isOrganizationOwner = this._access.checkIfItsOwner();
  }

}
