import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Person } from 'src/Models/person';


@Injectable({
  providedIn: 'root'
})
export class PersonService {

constructor(private http: HttpClient,
  @Inject('BASE_URL') private baseUrl: string) { }

  public addPerson(person: any): Observable<Person> {
    return this.http.post<Person>(`${this.baseUrl}api/person`, person);
  }

  
  public getAll(): Observable<Person[]> {
    return this.http.get<Person[]>(`${this.baseUrl}api/person`);
  }

  public getById(id: number): Observable<Person> {
    return this.http.get<Person>(`${this.baseUrl}api/person/${id}`);
  }

  public updatePerson(person: any, id: number): Observable<Person> {
    return this.http.put<Person>(this.baseUrl + 'api/person/' + id, person);
  }

  public deletePerson(id: number): Observable<Person> {
    return this.http.delete<Person>(this.baseUrl + 'api/person/' + id);
  }

  public transformPersonToOtherType(personId: number, request: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/person/transform/${personId}`, request);
}


}
