<!-- signup.component.html -->
<div class="container mw-100">
  <div class="right-column">
    <!-- The form for user sign-up -->
    <div class="right-column-inside">
      
      <img src="assets/inowaLabelW.png" alt="Inowa Lab">
      <h2 class="mb-4">Inscreva-se e vamos juntos acelerar a inovação! </h2>
      <p class="mb-4" style="color:#7cbeed; ">Já possui uma conta? <a href="/login">Login</a></p>
      <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col">
            <label for="firstName" class="mb-2">Nome</label>
            <input formControlName="firstName" class="form-control" id="firstName" required>
          </div>
          <div class="col">
            <label for="lastName" class="mb-2">Sobrenome</label>
            <input formControlName="lastName" class="form-control" id="lastName" required>
          </div>
        </div>
        <label for="email" class="mb-2" >Email</label>
        <input formControlName="email" id="email" class="form-control" required>
        <label for="password" class="mb-2">Senha</label>
        <div class="input-group">
          <input [type]="showPassword ? 'text' : 'password'" formControlName="password" id="password" class="form-control" required>
          <button class="btn btn-outline-secondary" type="button" (click)="togglePasswordVisibility()">
            <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </div>
        
        <!-- warning in case of password not meeting the requirements -->
        <p class="mb-2 warn" *ngIf="!signupForm.get('password')?.valid && signupForm.get('password')?.touched">A senha deve ter no mínimo 8 caracteres, letras maiúsculas, minúsculas, número e caracter especial</p>
        
        <label for="confirmPassword" class="mb-2">Confirmação de Senha</label>
        <div class="input-group">
          <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" id="confirmPassword" class="form-control" required>
          <button class="btn btn-outline-secondary" type="button" (click)="toggleConfirmPasswordVisibility()">
            <mat-icon>{{ showConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </div>
        <p class="mb-2 warn" *ngIf="!signupForm.get('confirmPassword')?.valid && signupForm.get('confirmPassword')?.touched">
          A confirmação da senha deve corresponder à senha inserida.
        </p>
        
        <!-- <p class="mb-2 warn" *ngIf="!signupForm.get('confirmPassword')?.valid && signupForm.get('confirmPassword')?.touched">As senhas não coincidem</p> -->
        <button class="submitted" type="submit" [disabled]="!signupForm.valid">Continue</button>
        <!-- whatsapp contact -->
        <p style="text-align: center;">Se inscrevendo, você concorda com os Termos de Serviço e Política de Privacidade</p>
        <div class="whatsapp-contact" style="text-align:end;">
          <a  href="https://wa.me/5521998771500" target="_blank" rel="noopener noreferrer">
            <img src="assets/whatsapp.png" style="width:50px; " alt="WhatsApp Icon">
          </a>
        </div>
        <div class="spinner" *ngIf="isLoading">
          <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="4"></mat-progress-spinner>
        </div>
      </form>
    </div>
  </div>
  <div class="left-column">
    
    
    <!-- <button class="initiateButton" routerLink="login">Join an applied innovation community</button> -->
  </div>
</div>
