import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/api/base/base.service';
import { Problem } from 'src/Models/problem';

@Injectable({
  providedIn: 'root'
})
export class ProblemService extends BaseService{

constructor(private http: HttpClient,
  @Inject('BASE_URL') private baseUrl: string) {
    super();
   }

  public addProblem(problem: any): Observable<Problem> {
    const headers = super.ObterAuthHeaderJson().headers;
    return this.http.post<Problem>(`${this.baseUrl}api/problem`, problem, { headers });
  }

  public getAll(): Observable<Problem[]> {
    return this.http.get<Problem[]>(`${this.baseUrl}api/problem`, super.ObterAuthHeaderJson());
  }

  public getById(id: number): Observable<Problem> {
    return this.http.get<Problem>(`${this.baseUrl}api/problem/${id}`, super.ObterAuthHeaderJson());
  }

  public getByOwner(id: number): Observable<Problem[]> {
    return this.http.get<Problem[]>(`${this.baseUrl}api/problem/person/${id}`, super.ObterAuthHeaderJson());
  }

  public updateProblem(problem: any, id: number): Observable<Problem> {
    return this.http.put<Problem>(this.baseUrl + 'api/problem/' + id, problem, super.ObterAuthHeaderJson());
  }

  public deleteProblem(id: number): Observable<Problem> {
    return this.http.delete<Problem>(this.baseUrl + 'api/problem/' + id, super.ObterAuthHeaderJson());
  }
  
}
