export enum RoleTypes {
    Professor = 'Professor',
    Estudante = 'Student',
    Pesquisador = 'Researcher',
    Empresario = 'Entrepreneur',
    Investidor = 'Investor',
    Funcionario_Publico_Estado = 'PublicAgentState',
    Funcionario_Publico_Municipio = 'PublicAgentCity',
    Funcionario_Publico_Federal = 'PublicAgentFederal',
    Funcionario_Publico_Legislativo = 'LegislativeAgent',
    Funcionario_Publico_Executivo = 'ExecutiveAgent',
    Funcionario_Publico_Judiciario = 'JudicialAgent',
    Empregado_Empresa_Privada = 'PrivateAgent',
    Sem_Vinculo = 'CommonUser',
}