import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-TypeOrganizationDialog',
  templateUrl: './TypeOrganizationDialog.component.html',
  styleUrls: ['./TypeOrganizationDialog.component.css']
})
export class TypeOrganizationDialogComponent {

  selectedTypeOrganizationId!: number;

  constructor(
    public dialogRef: MatDialogRef<TypeOrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { typeOrganizations: any[] }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectTypeOrganization(): void {
    this.dialogRef.close(this.selectedTypeOrganizationId);
  }
}
