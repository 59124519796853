<app-NavBar></app-NavBar>
<div class="container">
  <h1>Organization Approve</h1>
  <hr>
  
  <div class="mat-elevation-z8">
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      
      <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
    </ng-container>
    
    <!-- Phone Number Column -->
    <ng-container matColumnDef="phoneNumber">
      <mat-header-cell *matHeaderCellDef> Phone Number </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.phoneNumber}} </mat-cell>
    </ng-container>
    
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button (click)="approveOrganization(element, element.organizationId)">Activate</button>
        <button mat-button color="warn" (click)="denyOrganization(element, element.organizationId)">Inactivate</button>
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

  </div>