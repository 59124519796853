import { EmailConfirmationComponent } from './../api/base/user/emailConfirmation/emailConfirmation.component';
import { ChangePasswordComponent } from './../api/base/user/changePassword/changePassword.component';
import { UsersComponent } from './Administration/Users/Users.component';
import { MySolutionsComponent } from './Solution/MySolutions/MySolutions.component';
import { MyChallengesComponent } from './Problem/MyChallenges/MyChallenges.component';
import { MyProposalsComponent } from './Proposal/MyProposals/MyProposals.component';
import { ApproveOrganizationComponent } from './Administration/ApproveOrganization/ApproveOrganization.component';
import { AdministrationComponent } from './Administration/Administration.component';
import { SearchTableChallengeComponent } from './Problem/SearchTableChallenge/SearchTableChallenge.component';
import { SolutionComponent } from './Solution/Solution.component';
import { ProblemComponent } from './Problem/Problem.component';
import { DashboardComponent } from './Dashboard/Dashboard.component';
import { LoginComponent } from './../api/base/login/login.component';
import { NewUserComponent } from './../api/base/user/newUser/newUser.component';
import { HomeComponent } from './Home/Home.component';
import { InsertSolutionComponent } from './Solution/InsertSolution/InsertSolution.component';
import { InsertProblemComponent } from './Problem/InsertProblem/InsertProblem.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationComponent } from './Organization/Organization.component';
import { PersonFormComponent } from './PersonForm/PersonForm.component';
import { SearchTableSolutionComponent } from './Solution/SearchTableSolution/SearchTableSolution.component';
import { ProposalComponent } from './Proposal/Proposal.component';
import { ReceivedProposalsComponent } from './Proposal/ReceivedProposals/ReceivedProposals.component';

const routes: Routes = [
  { path: 'organization', component: OrganizationComponent },
  { path: 'person', component: PersonFormComponent },
  { path: 'problem', component: InsertProblemComponent},
  { path: 'solution', component: InsertSolutionComponent},
  { path: '', component: NewUserComponent, pathMatch: 'full' },
  { path: 'getStarted', component: NewUserComponent},
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'alteraSenha', component: ChangePasswordComponent},
  { path: 'confirmar-email', component: EmailConfirmationComponent},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'pageChallenge', component: ProblemComponent},
  { path: 'pageSolution', component: SolutionComponent},
  { path: 'newChallenge', component: InsertProblemComponent},
  { path: 'myChallenges', component: MyChallengesComponent},
  { path: 'myChallenges/:id', component: InsertProblemComponent},
  { path: 'newSolution', component: InsertSolutionComponent},
  { path: 'mySolutions', component: MySolutionsComponent},
  { path: 'mySolutions/:id', component: InsertSolutionComponent},
  { path: 'searchChallenges', component: SearchTableChallengeComponent},
  { path: 'searchSolutions', component: SearchTableSolutionComponent},
  { path: 'admin', component: AdministrationComponent},
  { path: 'view/:id', component: ApproveOrganizationComponent},
  { path: 'proposal', component: ProposalComponent},
  {path: 'proposal/receivedProposals', component: ReceivedProposalsComponent},
  { path: 'proposal/myProposals', component: MyProposalsComponent},
  { path: 'users', component: UsersComponent},
{path: 'home', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
