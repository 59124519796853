<app-PersonalNav [title]="title"></app-PersonalNav>
<hr>
<div class="container mw-100">
  <div class="row">
    <div class="col commands text-right">
      <button type="submit"
        [routerLink]="['/newChallenge']"
        mat-raised-button color="primary"
        style="margin-top: 30px; margin-bottom: 20px;">
        Adicionar Novo Desafio
      </button>
    </div>

    <div class="col">
      
      <div class="d-flex justify-content-center align-items-center">
        <h5>Filtro</h5>
        <div  fxLayout fxLayoutAlign="center center" style="margin-top: 10px; margin-left: 10px;">
          <mat-form-field fxFlex="60%">
            <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Buscar Desafio">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="problems">
       
      <!-- Id Column -->
      <ng-container matColumnDef="problemId">
        <th mat-header-cell *matHeaderCellDef> Id Desafio</th>
        <td mat-cell *matCellDef="let element"> {{element.problemId}} </td>
      </ng-container>
   
      <!-- Empresa Column -->
      <ng-container matColumnDef="organizationAtrelada">
        <th mat-header-cell *matHeaderCellDef> Empresa </th>
        <td mat-cell *matCellDef="let element"> {{element.ownerName}} </td>
      </ng-container>
  
      <!-- Area Colunm -->
      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef> Área </th>
        <td mat-cell *matCellDef="let element"> {{element.challengeField}} </td>  
      </ng-container>
  
      <!-- Tema Column -->
      <ng-container matColumnDef="theme">
        <th mat-header-cell *matHeaderCellDef> Nome do Desafio </th>
        <td mat-cell *matCellDef="let element"> {{element.theme}} </td>
      </ng-container>


  
      <!-- Descrição Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Descrição </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
  
      <!-- Objetivos Column -->
      <ng-container matColumnDef="mvp">
        <th mat-header-cell *matHeaderCellDef> Categoria </th>
        <td mat-cell *matCellDef="let element"> {{element.mvpFeat}} </td>
      </ng-container>
  
      <!-- MVP Features Column -->
      <ng-container matColumnDef="mvpFeatures">
        <th mat-header-cell *matHeaderCellDef> MVP Features </th>
        <td mat-cell *matCellDef="let element"> {{element.mvpFeatures}} </td>
      </ng-container>
  
      <!-- Available Funds Column -->
      <ng-container matColumnDef="availableFunds">
        <th mat-header-cell *matHeaderCellDef> Verba Disponível </th>
        <td mat-cell *matCellDef="let element"> {{element.availableFunds}} </td>
      </ng-container>
  
      <!-- Deadline Column -->
      <ng-container matColumnDef="deadline">
        <th mat-header-cell *matHeaderCellDef> Prazo </th>
        <td mat-cell *matCellDef="let element"> {{element.limitDate}} </td>
      </ng-container>
  
      <!-- Ganhos Operacionais Colunm -->
      <ng-container matColumnDef="operationalGain">
        <th mat-header-cell *matHeaderCellDef> Ganhos Operacionais </th>
        <td mat-cell *matCellDef="let element"> {{element.operationalGain}} </td>
      </ng-container>
  
      <!-- Ganhos Economicos -->
      <ng-container matColumnDef="economicGain">
        <th mat-header-cell *matHeaderCellDef> Ganhos Econômicos </th>
        <td mat-cell *matCellDef="let element"> {{element.economicGain}} </td>
      </ng-container>
  
      <!-- Maturidade Desejada (TRL) -->
      <ng-container matColumnDef="trl">
        <th mat-header-cell *matHeaderCellDef> TRL </th>
        <td mat-cell *matCellDef="let element"> {{element.trl}} </td>
      </ng-container>
  
      <!-- Verba Disponível -->
      <ng-container matColumnDef="availableBudget">
        <th mat-header-cell *matHeaderCellDef> Verba Disponível </th>
        <td mat-cell *matCellDef="let element"> {{element.availableFees | currency:'BRL'}} </td>
      </ng-container>
  
      <!-- Limit Date -->
      <ng-container matColumnDef="limitDate">
        <th mat-header-cell *matHeaderCellDef> Prazo </th>
        <td mat-cell *matCellDef="let element"> {{element.limitDate | date:'dd/MM/yyyy'}} </td>
      </ng-container>

      <!-- PDF Anexo -->
      <ng-container matColumnDef="pdfLink">
        <th mat-header-cell *matHeaderCellDef> PDF Anexo </th>
        <td mat-cell *matCellDef="let element"> {{element.urlPresentationData}} </td>
      </ng-container>
  
      <!-- Video Link -->
      <ng-container matColumnDef="videoLink">
        <th mat-header-cell *matHeaderCellDef> Video Link </th>
        <td mat-cell *matCellDef="let element"> {{element.videoLink}} </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
      </ng-container>
  
      <!-- Ações -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Ações </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" matTooltip="Editar Desafio" [routerLink]="['/myChallenges', element.problemId]" routerLinkActive="router-link-active">
            <fa-icon [icon]="faEdit" 
             ></fa-icon>
          </button>
          <button mat-icon-button color="warn" [matTooltip]="element.status === 'Ativo' ? 'Inativar' : 'Ativar'" (click)="archiveProblem(element)">
            <fa-icon [icon]="faArchive" 
             ></fa-icon>
          </button>
        </td>
      </ng-container>
      
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>