import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IdentityRoleService } from 'src/services/identityRole.service';

@Component({
  selector: 'app-AddRoleDialog',
  templateUrl: './AddRoleDialog.component.html',
  styleUrls: ['./AddRoleDialog.component.css']
})
export class AddRoleDialogComponent {
  roleName!: string;

  constructor(
    public dialogRef: MatDialogRef<AddRoleDialogComponent>,
    private _identityRole: IdentityRoleService,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onAddClick(): void {
    this._identityRole.createIdentityRole(this.roleName).subscribe({
      next: (response) => {
        this._toastr.success('Role created successfully');
        this.dialogRef.close();
      }
    });

  }
}