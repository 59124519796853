<ul class="nav justify-content-between">
  <li>
    <label mat-button (click)="goback()">
      <fa-icon [icon]="faArrowBack" style=" color:black; font-size: 2.0rem; margin-right: 20px; cursor: pointer;"></fa-icon>
    </label>
    <!-- icon for home -->
      <fa-icon [icon]="faHome"  [routerLink]="'/dashboard'" style="margin-top:10px; color:black; font-size: 2.5rem; margin-right: 20px; cursor: pointer;" ></fa-icon>
  </li>
  <li style="display: flex; flex-direction:column; justify-content:space-around; align-items:center; ">
    <img src="assets/inowaLabelW.png" [routerLink]="'/'" alt="">
    <h1>{{title}}</h1>
    <div class="menuAccess">
      <fa-icon [icon]="faPassword" (click)="alterSenha()" matTooltip="Alterar Senha" *ngIf="title === 'Inovador'"
      style="cursor:pointer; margin-right: 5px;"></fa-icon>
      <button class="btn btn" *ngIf="isMaster" (click)="goAdmin()" >Entrar no Admin</button>

    </div>
  </li>
  <li>
      <app-menuUser style="margin-right: 10px;"></app-menuUser>
  </li>
</ul>