import { library } from '@fortawesome/fontawesome-svg-core';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { PersonOrganizationService } from 'src/services/personOrganization.service';
import { PersonSolutionService } from 'src/services/personSolution.service';
import { PersonOrganizationRequest } from 'src/Models/PersonOrganizationRequest';

@Component({
  selector: 'app-AddOrganizationAdmin',
  templateUrl: './AddOrganizationAdmin.component.html',
  styleUrls: ['./AddOrganizationAdmin.component.css']
})
export class AddOrganizationAdminComponent implements OnInit {

  colaborators: any;
  selectedColaborator: any;
  label!: string;
  personOrganizationReq = <PersonOrganizationRequest>{};

  constructor(
    private dialogRef: MatDialogRef<AddOrganizationAdminComponent>,
    private _personOrganization: PersonOrganizationService,
    private _personSolution: PersonSolutionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService) { }

  ngOnInit() {

    this.label = this.data.label;
    let id = this.data.organizationId;
    this._personOrganization.getPersonByOrganization(id).subscribe({
      next: (data) => {
        this.colaborators = data;
      }
    });

  }

  //buscar solução data para cadastro de uma personSolution caso tenha solução
  //criar uma nova personSolution
  addNewPersonSolutionOrPersonOrganization() {

    if(this.data.solutionId == null || this.data.solutionId == undefined){
      this.personOrganizationReq = {
        personId: this.selectedColaborator,
        organizationId: this.data.organizationId,
        isAdmin: true,
        isOwner: false,
        isBlocked: false,
        isDeleted: false,
        isMember: true
      }

      this._personOrganization.addPersonOrganization(this.personOrganizationReq).subscribe({
        next: (data) => {
          this.toastr.success('Colaborador adicionado com sucesso');
          this.dialogRef.close();
        }
      });
    } else{
      const personSolution = {
        personId: this.selectedColaborator.id,
        solutionId: this.data.solutionId,
        contributionType: ''
      }
      
  
  
  
      //check if is there a solution
     this._personSolution.addPersonSolution(personSolution).subscribe({
        next: (data) => {
          this.toastr.success('Colaborador adicionado com sucesso');
          this.dialogRef.close();
        }
      });

    }

  }
  

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(user: any): void {

  }

}
