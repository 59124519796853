import { DialogService } from 'src/services/dialog.service';
import { ProposalService } from 'src/services/proposal.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProblemService } from 'src/services/problem.service';
import { SolutionService } from 'src/services/solution.service';
import { AccessService } from 'src/services/access.service';

@Component({
  selector: 'app-MyProposals',
  templateUrl: './MyProposals.component.html',
  styleUrls: ['./MyProposals.component.css']
})
export class MyProposalsComponent implements OnInit {
  displayedColumns: string[] = [
    'proposalId', 'organization', 'ownerName', 'challengeField', 
    'theme',   'trl', 
    
    'status', 'actions'	
  ];
  solutions!: any[];
  selectedSolution: any;
  

  statusOptions = ['Entregue', 'Em avaliação', 'Aprovada', 'Em contratação', 'Reprovada'];

  challengesInTable = new MatTableDataSource<any>();
  proposals: any;
  user: any;

  challenges: any;
  constructor(private _proposal: ProposalService,
    private _solution: SolutionService,
    private _access: AccessService,
    private dialog: DialogService) { }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.user = JSON.parse(localStorage.getItem('app.user')!);
    this.loadProposals();
    //this.getChallengesByOwner();
  }

  loadProposals() {
    this._proposal.getProposalsBySolutionResponsible(this.user.id)
      .subscribe(result => {
        console.log('Proposes received: ', result);

        const uniqueSolutions = result
          .map((p: { solution: any; }) => p.solution)
          .filter((solution: any, index: number, self: any[]) =>
            index === self.findIndex((s) => s.solutionId === solution.solutionId));
  
        this.proposals = result;
        this.solutions = uniqueSolutions;
        this.challenges = result.map((p: { problem: any; }) => p.problem);
      });
  }

  getSolutionsByOwner() {
    this._proposal.getProposalsBySolutionResponsible(this.user.id)
    .subscribe(result => {
      this.challengesInTable = new MatTableDataSource(result.map((p: { problem: any; }) => p.problem));
    })
  }


  openDialog(challenge: any, solution: any) {
    this.dialog.compareDialog(solution, challenge);
  }

  getProposalsBySolution(event: any) {
    let solutionId = event.value.solutionId;
    this._proposal.getProposalBySolution(solutionId)
    .subscribe(result => {
      //this.proposals = result;
      this.challenges = result.map((p: { challenge: any; }) => p.challenge );
      
    });
  }

  updateStatus(element: any, target: any) {
    // Update the status of the element, might need to update the data source or server
    if (target instanceof HTMLSelectElement) {
      const newStatus = target.value;
      element.status = newStatus;
      // Implement the logic to update this status in your data source or backend
    }
    // Implement the logic to update this status in your data source or backend
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.proposals.filter = filterValue.trim().toLowerCase();
  }

}

