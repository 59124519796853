import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Organization } from 'src/Models/organization';
import { BaseService } from 'src/api/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends BaseService {

constructor(private http: HttpClient,
  @Inject('BASE_URL') private baseUrl: string) {
    super();
   }

  public addOrganization(organization: any): Observable<Organization> {
    return this.http.post<Organization>(`${this.baseUrl}api/organization`, organization, super.ObterAuthHeaderJson());
  }

  public getAll(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.baseUrl}api/organization`, super.ObterAuthHeaderJson());
  }

  public getById(id: number): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseUrl}api/organization/${id}`, super.ObterAuthHeaderJson());
  }

  public getDefaultOrganization(): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseUrl}api/organization/default`, super.ObterAuthHeaderJson());
  }

  public getOrganizationByResponsibleEmail(email: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseUrl}api/organization/responsible/${email}`, super.ObterAuthHeaderJson());
  }

  public getOrganizationByClassification(classification: string): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.baseUrl}api/organization/classification/${classification}`, super.ObterAuthHeaderJson());
  }

  public getOrganizationByPersonId(personId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.baseUrl}api/organization/person/${personId}`, super.ObterAuthHeaderJson());
  }

  public updateOrganization(organization: any, id: number): Observable<Organization> {
    return this.http.put<Organization>(this.baseUrl + 'api/organization/' + id, organization, super.ObterAuthHeaderJson());
  }

  public deleteOrganization(id: number): Observable<Organization> {
    return this.http.delete<Organization>(this.baseUrl + 'api/organization/' + id, super.ObterAuthHeaderJson());
  }
}
