import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from 'src/services/notification.service';
import { User } from '../user';
import { UserService } from '../user.service';
import { createPasswordStrengthValidator } from '../validators/password-strength.validators';

@Component({
  selector: 'app-newUser',
  templateUrl: './newUser.component.html',
  styleUrls: ['./newUser.component.css'],

})
export class NewUserComponent {
  showPassword = false;
  showConfirmPassword = false;
  user = <User>{};
  signupForm: FormGroup;
  isLoading = false;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor(private fb: FormBuilder,
    private router: Router,
    private notification: NotificationService,
    private userService: UserService) { 
    this.signupForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        },
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.min(8),
          createPasswordStrengthValidator(),
        ],
      ],
      confirmPassword: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.isLoading = true;
    if (this.signupForm.invalid) {
      this.isLoading = false;
      return;
    }
    let name = this.signupForm.value.firstName + ' ' + this.signupForm.value.lastName;
    Object.assign(this.user, {
      nome: name,
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
    });
    this.registerUser(this.user);
  }

  

 

  registerUser(user: User) {
    Object.assign(user, {
        confirmPassword: this.signupForm.value.confirmPassword,
    });

    this.userService.cadastrar(user).subscribe({
        next: (result) => this.onSaveComplete(result),
        error: (e) => this.onError(e),
    });
}



onSaveComplete(response: any) {
  this.notification.success('Confirme seu cadastro no email referido!');
  this.isLoading = false;
    this.router.navigate(['./login']);
}

onError(fail: any) {
  console.log(fail);
  this.isLoading = false;
  if (fail.message.includes('is already taken')) {
    this.notification.error('Usuário já cadastrado! Caso tenha esquecido a senha, clique em "Esqueci minha senha"!')
  } else {
    this.notification.error('Verifique se as senhas coincidem e possuem caracteres obrigatórios (letras maiúsculas, minúsculas, números e caracter especial)!');
  }
}


togglePasswordVisibility(): void {
  this.showPassword = !this.showPassword;
}

toggleConfirmPasswordVisibility(): void {
  this.showConfirmPassword = !this.showConfirmPassword;
}


}
