import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/api/base/base.service';
import { UserRoleAssignmentModel } from 'src/Models/UserRoleAssignmentModel';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseService{

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { 
    super()
  }

  getUserRoles(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/UserRoles/usersWithRoles', super.ObterAuthHeaderJson());
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'api/userRoles', super.ObterAuthHeaderJson());
  }

  getRoleByUserId(userId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl + 'api/roles'}/${userId}`, super.ObterAuthHeaderJson());
  }

  createRole(user: UserRoleAssignmentModel): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/roles', user, super.ObterAuthHeaderJson());
    
  }

  deleteRole(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl + 'api/roles'}/${id}`, super.ObterAuthHeaderJson());
  }

  updateRole(id: string, newRoleName: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl + 'api/roles'}/${id}`, newRoleName, super.ObterAuthHeaderJson());
  }
}