<ul class="nav justify-content-around">
  <li>
    <img src="assets/inowaLabelW.png" [routerLink]="'/'" style="margin-top:10px; margin-left: 10px; cursor: pointer;" alt="">
  </li>
  <li>
    <button mat-button (click)="goback()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </li>
  <app-menuUser style="margin-right: 10px;"></app-menuUser>
</ul>