<app-NavBar></app-NavBar>
<div class="container mw-100 mt-5">
  <mat-grid-list cols="{{grid.cols}}" rowHeight="{{grid.rowHeight}}" [gutterSize]="grid.gutter">
    <mat-grid-tile *ngFor="let tile of tiles, let i = index">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{tile.icon}}"></mat-icon>
          <mat-card-title>
            <h4>{{tile.text}}</h4>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{tile.content}}</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="viewTile(tile.id || i)">VIEW</button>  
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>

