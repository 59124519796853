import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faArchive, faDoorOpen, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Problem } from 'src/Models/problem';
import { DialogService } from 'src/services/dialog.service';
import { OrganizationService } from 'src/services/organization.service';
import { ProblemService } from 'src/services/problem.service';

@Component({
  selector: 'app-MyChallenges',
  templateUrl: './MyChallenges.component.html',
  styleUrls: ['./MyChallenges.component.css']
})
export class MyChallengesComponent implements OnInit {
  title = 'Meus Desafios';

  faUnlockAlt = faDoorOpen;
  faEdit = faEdit;
  faArchive = faArchive;
  problems = new MatTableDataSource<Problem>();
    displayedColumns: string[] = 
    [
    'problemId', 
    'organizationAtrelada', 
    'area', 
    'theme', 
    'description',
    'trl', 
    'availableBudget',
    'limitDate', 
    'status',
    'actions'
  ];
  user: any;
    constructor(private _challenge: ProblemService,
      private _organization: OrganizationService,
      private dialog: DialogService,
      private router: Router) { }
  
    ngOnInit() {
      this.user = JSON.parse(localStorage.getItem('app.user')!);
      this.loadChallenges();
    }
  
    loadChallenges() {
      this._challenge.getAll().subscribe(result => {
        result = result.filter((problem: Problem) => problem.ownerId == this.user.id);
        result.forEach((problem: Problem) => {
          this.organizationName(problem);
        });
        this.problems = new MatTableDataSource<Problem>(result);
      });
    }
  
    applyFilter(filterValue: any) {
      filterValue = filterValue.target.value;
      filterValue = filterValue.trim();
      filterValue = filterValue.toLocaleLowerCase();
      this.problems.filter = filterValue.trim().toLowerCase();
    }
  
    organizationName(problem: Problem) {
      this._organization.getById(problem.organizationId).subscribe(result => {
        problem.ownerName = result.name;
        this.problems.data = this.problems.data.map(p => p.problemId === problem.problemId ? problem : p);
      });
    }
  
    openDialog(element?: any) {
      const dialogRef = this.dialog.openProposal(element);
      // const dialogRef = this.dialog.open(InsertProblemComponent);
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log(`Dialog result: ${result}`);
      // });
    }
  
    delete(element: any) {
      this._challenge.deleteProblem(element.problemId).subscribe(
        () => {
          console.log('Problem deleted successfully!');
          this.loadChallenges();
        },
        error => {
          console.log(error);
        }
      )
    }

    archiveProblem(element: any) {
      if(element.status == 'Ativo'){
        this.dialog.confirmDialog('Inativar Desafio', 'Deseja realmente inativar este desafio?').subscribe(
          (result: any) => {
            if(result){
              let data = element;
              data.status = 'Inativo';
              this._challenge.updateProblem(data, element.problemId).subscribe(
                () => {
                  console.log('Problem updated successfully!');
                  this.loadChallenges();
                },
                error => {
                  console.log(error);
                }
              )
            }
          }
        )
      }else{
        this.dialog.confirmDialog('Ativar Desafio', 'Deseja realmente ativar este desafio?').subscribe(
          (result: any) => {
            if(result){
              let data = element;
              data.status = 'Ativo';
              this._challenge.updateProblem(data, element.problemId).subscribe(
                () => {
                  console.log('Problem updated successfully!');
                  this.loadChallenges();
                },
                error => {
                  console.log(error);
                }
              )
            }
          }
        )
      }
    }
}
