import { Injectable } from '@angular/core';
import { Notyf } from 'notyf';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notyf = new Notyf();

  constructor() { }

  public success(message: string): void {
    this.notyf.success(message);
  }

  public error(message: string): void {
    this.notyf.error(message);
  }

  // Add other methods as needed for different types of notifications
}

