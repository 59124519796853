<app-PersonalNav [title]="'Menu Solução'"></app-PersonalNav>
<div class="container">
  


  <!-- Section Organization -->
  <div class="section" id="organization" *ngIf="isAdminOrOwner">
    <img src="assets/iconFind.png" alt="Organization Icon" class="icon">
    <h2 routerLink="/searchSolutions" [routerLinkActive]="['active']">BUSCAR SOLUÇÃO</h2>
  </div>

  <!-- Section Challenge -->
  <div class="section" id="challenge" >
    <img src="assets/iconSolution.png" alt="Challenge Icon" class="icon">
    <h2 routerLink="/mySolutions" [routerLinkActive]="['active']">MINHAS SOLUÇÕES</h2>
  </div>

  <!-- Section Solution -->
  <div class="section" id="solution" >
    <img src="assets/iconCadastro.png" alt="Solution Icon" class="icon">
    <h2 routerLink="/newSolution" [routerLinkActive]="['active']">CADASTRAR SOLUÇÃO</h2>
  </div>
</div>
