<div [ngSwitch]="userLogado()" class="nav navbar-nav navbar-right" style="text-align: center;" >
  <li *ngSwitchCase="false">
    <a  class="nav-link text-dark" [routerLink]="['/login']" style="cursor: pointer;">
      <fa-icon [icon]="faRightToBracket"></fa-icon>
    </a>
  </li>
  <li *ngSwitchCase="true">
    <a  class="nav-link text-dark" [routerLink]="'/person'"  style="cursor: pointer;">
      <!-- Avatar for uploading photo -->
      <div *ngIf="possuiFoto" class="pictureProfile">
        <img src="{{env}}uploads/{{nomeFoto}}" alt="Avatar"  class="avatar">
      </div>

      <img *ngIf="!possuiFoto" src="assets/iconProfile.png" alt="Avatar"  class="avatar">
    </a>
  </li>
  <li *ngSwitchCase="true"><a  class="nav-link text-dark">{{ saudacao }}</a></li>
  <li *ngSwitchCase="true">
    <button mat-raised-button (click)="logout()">Logoff</button>
    <!-- <a  class="nav-link text-dark"  style="cursor: pointer;">Logoff</a> -->
  </li>
</div>