import { ProposalService } from 'src/services/proposal.service';
import { OrganizationService } from './../../services/organization.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/api/base/user/user.service';
import { DashboardService } from 'src/services/dashboard.service';
import { PersonService } from 'src/services/person.service';
import { AccessService } from 'src/services/access.service';

@Component({
  selector: 'app-Dashboard',
  templateUrl: './Dashboard.component.html',
  styleUrls: ['./Dashboard.component.css']
})
export class DashboardComponent implements OnInit {
isCompleted: any;
isThereProblem = false;
isThereSolution = false;
isThereProposal = false;
isOrganizationOwner = false;
isMaster = false;
  user: any;

  constructor(private _person: PersonService,
    private _user: UserService,
    private _access: AccessService,
    private _dashboard: DashboardService,
    private _organization: OrganizationService,
    private _proposal: ProposalService) { }

  ngOnInit() {
    this.user = this._user.obterUsuario();
    this.checkIfPersonIsOwner();
    this._access.checkIfItsLogedIn();
    this.getStatusFromPerson(this.user.id);
    this.checkIfPersonIsCompleted();
    this.checkIfIsThereAnOrganizationInPersonsName();
    //this.checkIfThereIsAProposal();
    this.isMaster = this._access.checkIfItsMaster();
  }

  getStatusFromPerson(id: number) {
    this._dashboard.getDashboardStatus(id).subscribe(result => {
      this.isThereProblem = result.problemsCompleted;
      this.isThereSolution = result.solutionsCompleted;
    });
  }

  checkIfPersonIsOwner() {
    this.isOrganizationOwner = this._access.checkIfItsOwner();
  }


  checkIfPersonIsCompleted() {
    this._person.getById(this.user.id).subscribe(result => {
      this.isCompleted = result.completedProfile;
    });
  }

  checkIfIsThereAnOrganizationInPersonsName(){
    this._organization.getAll().subscribe(result => {
      let organization = result.find(x => x.responsavelInclusao == this.user.nome);
      if(organization){
        //save in localstorage
        localStorage.setItem('organizationUser', JSON.stringify(organization));
      }
    });
  }

  checkIfThereIsAProposal() {
          this._proposal.getProposalsBySolutionResponsible(this.user.id).subscribe(
        (result: any) => {
          if(result.length > 0){
            this.isThereProposal = true;
          console.log(result);
        }
    });
  }



}
