import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

constructor(private http: HttpClient,
  @Inject('BASE_URL') private baseUrl: string) { }

public getDashboardStatus(id: number): Observable<any> {
  return this.http.get(this.baseUrl + 'api/person/dashboard-status?userId=' + id);
} 

  
}
