<div mat-dialog-content class="mat-typography">
  <!-- Seção de Detalhes do Problema -->
  <div class="row">
    <div class="col challengeDetails">
      <h2 class="dialog-section-title mb-4">Detalhes do Desafio</h2>
      <mat-list>
        <div class="row mb-4 line">
          
          <div class="col">
            <mat-list-item><strong>Data limite: </strong> {{problem.limitDate | date:'dd/MM/yyyy'}}</mat-list-item>
          </div>
        </div>
        <hr>
       <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong>Empresa:</strong> {{ problem.ownerName }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          <div class="col  line">
            
            <mat-list-item><strong>Área:</strong> {{ problem.challengeField }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          
          <div class="col">
            
            <mat-list-item><strong>Tema:</strong> {{ problem.theme }}</mat-list-item>
          </div>
        </div>

        <div class="row mb-4 line">
          <div class="col" style="line-height: 1.0rem;">
            
            <mat-list-item><strong>Descrição:</strong> {{ problem.description }}</mat-list-item>
          </div>
        </div>

        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong matTooltip="(TRL)">Maturidade Esperada :</strong> {{ problem.trl }}</mat-list-item>
          </div>
        </div>
        
        <div class="row mb-4 line">
          
          <div class="col">
            <mat-list-item><strong>Objetivos: </strong> {{problem.theme}}</mat-list-item>
          </div>
        </div>
        

        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong>Ganhos operacionais:</strong> {{ problem.operationalGain }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong>Ganhos estratégicos:</strong> {{ problem.economicGain }}</mat-list-item>
          </div>
        </div>

        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong matTooltip="(Faixa de valor do investimento para 1ª versão do produto)">$ Verba Disponível:</strong> {{ problem.availableFees }}</mat-list-item>
          </div>
        </div>
        
      </mat-list>

    </div>
    
    <div class="col">

      <h2 class="dialog-section-title">Detalhes da Solução</h2>

      <mat-list>
        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong>Empresa:</strong> {{ solution.organizationResponsibleName }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          <div class="col">
            
            <mat-list-item><strong>Área:</strong> {{ solution.typeOfSolution }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          <div class="col">
            
            <mat-list-item><strong>Tema:</strong> {{ solution.typeOfSolution }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          <div class="col"> 
            <mat-list-item><strong>Descrição Solução:</strong> {{ solution.description }}</mat-list-item>
          </div>
        </div>

        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong>Maturidade Esperada (TRL):</strong> {{ solution.trl }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          <div class="col">
            
            <mat-list-item><strong>Objetivos:</strong> {{ solution.objectives }}</mat-list-item>
          </div>
        </div>

        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong>Ganhos operacionais:</strong> {{ solution.operationalGain }}</mat-list-item>
          </div>
        </div>
        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong>Ganhos estratégicos:</strong> {{ solution.economicGain }}</mat-list-item>
          </div>
        </div>

        <div class="row mb-4 line">
          <div class="col">
            <mat-list-item><strong matTooltip="(Faixa de valor do investimento para 1ª versão do produto)">$ Verba Disponível:</strong> {{ solution.availableFees }}</mat-list-item>
          </div>
        </div>
      </mat-list>  

</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
  
</mat-dialog-actions>
