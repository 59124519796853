<app-PersonalNav [title]="title"></app-PersonalNav>
<hr>
<div class="container mw-100">
  <div class="row">

    <div class="col commands text-right">
      <button type="submit"
        (click)="openDialog()"
        mat-raised-button color="primary"
        style="margin-top: 30px; margin-bottom: 20px;">
        Adicionar Nova Solução
      </button>
    </div>
    <div class="col">
      
      <div class="d-flex justify-content-center align-items-center">
        <h5>Filtro</h5>
        <div  fxLayout fxLayoutAlign="center center" style="margin-top: 10px; margin-left: 10px;">
          <mat-form-field fxFlex="60%">
            <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Buscar Solução">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <table mat-table [dataSource]="solutions" class="mat-elevation-z8">
      
      <!-- Id Column -->
      <ng-container matColumnDef="solutionId">
        <th mat-header-cell class="mat-header-cell" *matHeaderCellDef> Id Solução </th>
        <td mat-cell *matCellDef="let element"> {{element.solutionId}} </td>
      </ng-container>

      <!-- Organization Responsible Name Column -->
      <ng-container matColumnDef="organizationName">
        <th mat-header-cell *matHeaderCellDef matTooltip="Nome do Inovador"> Empresa </th>
        <td mat-cell *matCellDef="let element"> {{element.organizationResponsibleName}} </td>
      </ng-container>


      <!-- Area Colunm -->
      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef matTooltip="Área da Solução"> Área </th>
        <td mat-cell *matCellDef="let element"> {{element.typeOfSolution}} </td>  
      </ng-container>

      <!-- Type of Solution -->
      <ng-container matColumnDef="typeOfSolution">
        <th mat-header-cell *matHeaderCellDef> Tipo de Solução </th>
        <td mat-cell *matCellDef="let element"> {{element.typeOfSolution}} </td>
      </ng-container>

      <!-- Theme -->
      <ng-container matColumnDef="theme">
        <th mat-header-cell *matHeaderCellDef matTooltip="Tema central da Solução"> Tema </th>
        <td mat-cell *matCellDef="let element"> {{element.typeOfSolution}} </td>
      </ng-container>

      <!-- Descrição Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef matTooltip="Descrição do problema que a solução resolve"> Desafio </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <!-- Objetivos Column -->
      <ng-container matColumnDef="objectives">
        <th mat-header-cell *matHeaderCellDef matTooltip="Características e funcionalidades da Solução"> Objetivos </th>
        <td mat-cell *matCellDef="let element"> {{element.objectives}} </td>
      </ng-container>

      <!-- MVP -->
      <ng-container matColumnDef="mvp">
        <th mat-header-cell *matHeaderCellDef matTooltip="Aspectos mínimos que tornam a solução viável"> Categoria</th>
        <td mat-cell *matCellDef="let element"> {{element.mvp}} </td>
      </ng-container>


      <!-- Ganhos Operacionais Colunm -->
      <ng-container matColumnDef="operationalGain">
        <th mat-header-cell *matHeaderCellDef> Ganhos Operacionais </th>
        <td mat-cell *matCellDef="let element"> {{element.operationalGain}} </td>
      </ng-container>

      <!-- Ganhos Economicos -->
      <ng-container matColumnDef="economicGain">
        <th mat-header-cell *matHeaderCellDef> Ganhos Econômicos </th>
        <td mat-cell *matCellDef="let element"> {{element.economicGain}} </td>
      </ng-container>

      <!-- Maturidade Desejada (TRL) -->
      <ng-container matColumnDef="trl">
        <th mat-header-cell *matHeaderCellDef matTooltip="(TRL)"> Maturidade Desejada  </th>
        <td mat-cell *matCellDef="let element"> {{element.trl}} </td>
      </ng-container>

      <!-- Verba Disponível -->
      <ng-container matColumnDef="availableFees">
        <th mat-header-cell *matHeaderCellDef> Verba Disponível </th>
        <td mat-cell *matCellDef="let element"> {{element.availableFees | currency:'BRL'}} </td>
      </ng-container>

      <!-- PDF Anexo -->
      <ng-container matColumnDef="urlPresentationData">
        <th mat-header-cell *matHeaderCellDef> PDF Anexo </th>
        <td mat-cell *matCellDef="let element"> {{element.urlPresentationData}} </td>
      </ng-container>

      <!-- Video Link -->
      <ng-container matColumnDef="videoLink">
        <th mat-header-cell *matHeaderCellDef> Video Link </th>
        <td mat-cell *matCellDef="let element"> {{element.videoLink}} </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status === true ? 'Ativo' : 'Inativo'}} </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Ações </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" matTooltip="Editar Solução" [routerLink]="['/mySolutions', element.solutionId]">
            <fa-icon [icon]="faEdit" 
             ></fa-icon>
          </button>
          <button mat-icon-button color="warn" [matTooltip]="element.status === true ? 'Inativar' : 'Ativar'" (click)="archiveSolution(element)">
            <fa-icon [icon]="faArchive" 
             ></fa-icon>
          </button>
        </td>
      </ng-container>


      <!-- Buttons Column -->

      
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>