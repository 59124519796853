import { AccessService } from 'src/services/access.service';
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/services/dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-Solution',
  templateUrl: './Solution.component.html',
  styleUrls: ['./Solution.component.css']
})
export class SolutionComponent implements OnInit {

  isCompleted = false;
  isAdminOrOwner = false;

  constructor(private _access: AccessService,
    private _dialog: DialogService,
    private _router: Router
    ) { }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    let isProfileComplete = this._access.checkIfProfileIsComplete();
    if(!isProfileComplete){
      this._dialog.openDialog('Perfil Incompleto', 'Complete seu perfil para acessar essa funcionalidade', 'warning');
      this._router.navigate(['dashboard']);
    }
    this.checkIfPersonIsAdmin();
    
  }
  
  checkIfPersonIsAdmin() {
    this.isAdminOrOwner = this._access.checkIfItsOwner();
  }

}
