<app-PersonalNav [title]="'Menu Desafio'"></app-PersonalNav>
<div class="container">
  

  <!-- Section Organization -->
  <div class="section" id="organization" >
    <img src="assets/iconFind.png" alt="Organization Icon" class="icon">
    <h2 routerLink="/searchChallenges" [routerLinkActive]="['active']">BUSCAR DESAFIO</h2>
  </div>

  <!-- Section Challenge -->
  <div class="section" id="challenge" *ngIf="isOrganizationOwner">
    <img src="assets/iconChallenge.png" alt="Challenge Icon" class="icon" >
    <h2 routerLink="/myChallenges" [routerLinkActive]="['active']">MEUS DESAFIOS</h2>
  </div>

  <!-- Section Challenge -->
  <div class="section" id="solution" *ngIf="isOrganizationOwner" >
    <img src="assets/iconCadastro.png" alt="Solution Icon" class="icon">
    <h2 routerLink="/newChallenge" [routerLinkActive]="['active']">CADASTRAR DESAFIO</h2>
  </div>
</div>
