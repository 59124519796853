import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoleService } from 'src/services/userRole.service';

@Component({
  selector: 'app-AssignDialogRole',
  templateUrl: './AssignDialogRole.component.html',
  styleUrls: ['./AssignDialogRole.component.css']
})
export class AssignDialogRoleComponent implements OnInit {

  selectedRole: any;
  roles: any;

  constructor(
    public dialogRef: MatDialogRef<AssignDialogRoleComponent>,
    private roleService: UserRoleService,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit() {
    this.roles = this.data.roles;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(selectedRole: any): void {
    const data = {
      userId: this.data.userId,
      roleId: selectedRole
    };
    this.roleService.assignUserRole(data).subscribe({
      next: (response) => {
        console.log('Role assigned successfully:', response);
      }
    });
    this.dialogRef.close();

  }
}