import { Solution } from './../../../Models/solution';
import { Problem } from './../../../Models/problem';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-InfoTableDialog',
  templateUrl: './InfoTableDialog.component.html',
  styleUrls: ['./InfoTableDialog.component.css']
})
export class InfoTableDialogComponent implements OnInit {
  title: string = '';

  problem?: Problem;
  solution?: Solution;  
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(this.data.dataProblem){
      this.problem = this.data.dataProblem;
      this.title = 'Desafio';
    } else if(this.data.dataSolution){
      this.solution = this.data.dataSolution;
      this.title = 'Solução';
    }

  }

  


}
