import { UserRoleService } from 'src/services/userRole.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/api/base/user/user.service';

@Component({
  selector: 'app-AssignAdminToOrganization',
  templateUrl: './AssignAdminToOrganization.component.html',
  styleUrls: ['./AssignAdminToOrganization.component.css']
})
export class AssignAdminToOrganizationComponent implements OnInit {

  email!: string;
  emailExists: boolean | null = null;
  emailInvalid: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AssignAdminToOrganizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private userService: UserService,
    private userRole: UserRoleService
  ) {}

  ngOnInit() {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    if (this.emailExists) {
      this.toastr.success('Email already exists');
      this.dialogRef.close({ email: this.email });
    } else {
      this.toastr.error('Email não pertence a nenhum usuário válido');
    }
  }

  validateEmail(): void {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    this.emailInvalid = !emailPattern.test(this.email);
    if (!this.emailInvalid) {
      this.checkEmail();
    } else {
      this.emailExists = null;
    }
  }

  checkEmail(): void {
    this.userRole.checkIfEmailExists(this.email).subscribe(
      (response) => {
        this.emailExists = response;
      },
      (error) => {
        this.emailExists = null;
        this.toastr.error('Error checking email');
      }
    );
  }
}