<mat-dialog-content class="mat-typography">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Detalhes {{ title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <!-- Conditional display for Problem or Solution -->
      <div *ngIf="problem">
        <h4 class="card-title">Problema: {{ problem.problemId }}</h4>
            <p class="card-text"><strong>ID do Proprietário:</strong> {{ problem.ownerId }}</p>
            <p class="card-text"><strong>Campo de Desafio:</strong> {{ problem.challengeField }}</p>
            <p class="card-text"><strong>Tema:</strong> {{ problem.theme }}</p>
            <p class="card-text"><strong>Descrição:</strong> {{ problem.description }}</p>
            <p class="card-text"><strong>Características MVP:</strong> {{ problem.mvpFeat }}</p>
            <p class="card-text"><strong>Ganho Operacional:</strong> {{ problem.operationalGain }}</p>
            <p class="card-text"><strong>Ganho Econômico:</strong> {{ problem.economicGain }}</p>
            <p class="card-text"><strong>Nível de Prontidão Tecnológica (TRL):</strong> {{ problem.trl }}</p>
            <p class="card-text"><strong>Taxas Disponíveis:</strong> {{ problem.availableFees }}</p>
            <p class="card-text"><strong>Dados da Apresentação:</strong> <a href="{{ problem.urlPresentationData }}" target="_blank">Link</a></p>
            <p class="card-text"><strong>Link do Vídeo:</strong> <a href="{{ problem.videoLink }}" target="_blank">Assistir</a></p>
            <p class="card-text"><strong>Data Limite:</strong> {{ problem.limitDate | date }}</p>
            <p class="card-text"><strong>Ativo:</strong> {{ problem.status ? 'Sim' : 'Não' }}</p>
      </div>
      <div *ngIf="solution">
        <h4 class="card-title">Solução: {{ solution.solutionId }}</h4>
            <p class="card-text"><strong>Nome da Empresa Responsável:</strong> {{ solution.organizationResponsibleName }}</p>
            <p class="card-text"><strong>ID da Empresa Responsável:</strong> {{ solution.organizationResponsibleId }}</p>
            <p class="card-text"><strong>Tipo de Solução:</strong> {{ solution.typeOfSolution }}</p>
            <p class="card-text"><strong>Descrição:</strong> {{ solution.description }}</p>
            <p class="card-text"><strong>Ganho Econômico:</strong> {{ solution.economicGain }}</p>
            <p class="card-text"><strong>Ganho Operacional:</strong> {{ solution.operationalGain }}</p>
            <p class="card-text"><strong>Objetivos:</strong> {{ solution.objectives }}</p>
            <p class="card-text"><strong>Nível de Prontidão Tecnológica (TRL):</strong> {{ solution.trl }}</p>
            <p class="card-text"><strong>Taxas Disponíveis:</strong> {{ solution.availableFees }}</p>
            <p class="card-text"><strong>Apresentação:</strong> <a href="{{ solution.urlPresentation }}" target="_blank">Link</a></p>
            <p class="card-text"><strong>Link do Vídeo:</strong> <a href="{{ solution.videoLink }}" target="_blank">Assistir</a></p>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">Fechar</button>
</mat-dialog-actions>
