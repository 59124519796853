import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
  private http: HttpClient) { }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post( this.baseUrl + 'api/uploads', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
