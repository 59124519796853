<h1 mat-dialog-title>Select Organization Type</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Type of Organization</mat-label>
    <mat-select [(value)]="selectedTypeOrganizationId" >
      <mat-option *ngFor="let type of data.typeOrganizations" [value]="type.id">
        {{ type.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="selectedTypeOrganizationId" cdkFocusInitial>Ok</button>
</div>
