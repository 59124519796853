import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../user.service';

@Component({
  selector: 'app-resetPassword',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form!: FormGroup;
  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
  private _user: UserService) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
    }
    )
  }
  confirmationReset() {
    this.dialogRef.close(true);
    this._user.resetPass(this.form.value.email)
      .subscribe(
        result => console.log(result)
        )
  }


}
