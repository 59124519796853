<h1 mat-dialog-title>Adicionar nova role</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Digite o nome da nova role</mat-label>
    <input matInput [(ngModel)]="roleName">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onAddClick()">Add</button>
</div>
