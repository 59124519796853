import { ToastrService } from 'ngx-toastr';
import { DialogService } from './../../../services/dialog.service';
import { UserService } from './../user/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../user/user';
import { Router } from '@angular/router';
import { NotificationService } from 'src/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user!: User;
  form: FormGroup = this.fb.group({
    email: [''],
    password: ['']
  });
  isLoading = false;
  hidePassword = true;
  errors: any;

  constructor(private fb: FormBuilder,
    private router: Router,
    private notfy: NotificationService,
    private _dialog: DialogService ,
    private _toastService: ToastrService,
    private _spinner: NgxSpinnerService,
    private userService: UserService) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login() {
    this._spinner.show();
    if (this.form.valid && this.form.dirty) {
      let _user = Object.assign({}, this.user, this.form.value);
      this.userService.login(_user).subscribe({
        next: (result) => this.onSaveComplete(result),
        error: (e) => this.onError(e),
      });
    }
  }

  onSaveComplete(response: any) {
    this._spinner.hide();
    this.userService.persistirUserApp(response);
    this.notfy.success('Seja bem vindo!');
    this.router.navigateByUrl('/dashboard');
  }

  onError(fail: any) {
    this._spinner.hide();
    //this._toastService.error('usuário ou senha incorretos', 'Tente novamente');
    this.notfy.error('Usuário ou senha incorretos');
    this.errors = fail;
  }

  logout() {
    //remove just if data is stored in the local storage - it needs to be checked
    
    localStorage.removeItem('app-token');
    localStorage.removeItem('app-user');
    localStorage.removeItem('organizationUser');
    localStorage.removeItem('personOrganization');
    
  }

  resetDeSenha() {
    //after close the dialog, use a message to show the user will receive an email to reset the password
    const dialogRef = this._dialog.resetPassword();

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { // Only show the toast if the result is `true`
        this._toastService.success('Um email foi enviado para você com as instruções para redefinir sua senha');
      }
    });
  
  }

}
