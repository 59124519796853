<app-PersonalNav [title]="'Menu Proposta'"></app-PersonalNav>
<div class="container">
  

  <!-- Section Challenge -->
  <div class="section" id="challenge" *ngIf="isOrganizationOwner" >
    <img src="assets/iconFind.png" alt="Challenge Icon" class="icon">
    <h2 routerLink="receivedProposals" [routerLinkActive]="['active']">RECEBIDAS</h2>
  </div>

  <!-- Section Challenge -->
  <div class="section" id="solution" >
    <img src="assets/iconProposal.png" alt="Solution Icon" class="icon">
    <h2 routerLink="myProposals" [routerLinkActive]="['active']">SUBMETIDAS</h2>
  </div>
</div>
