import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Problem } from 'src/Models/problem';
import { Solution } from 'src/Models/solution';

@Component({
  selector: 'app-NotificationDialog',
  templateUrl: './NotificationDialog.component.html',
  styleUrls: ['./NotificationDialog.component.css']
})
export class NotificationDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

}

export interface DialogData {
  title: string;
  message: string;
  type: 'success' | 'error' | 'warning';
}

export interface DialogDataProposal {
  problem?: Problem;
  solution?: Solution;
}

