<h1 mat-dialog-title>Assign Role</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Role</mat-label>
    <mat-select [(value)]="selectedRole">
      <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onYesClick(selectedRole)" cdkFocusInitial>Assign</button>
</div>