import { MatDialog } from '@angular/material/dialog';
import { TypeOrganizationService } from './../../../services/typeOrganization.service';
import { UserRoleService } from './../../../services/userRole.service';
import { DialogService } from 'src/services/dialog.service';
import { Component, OnInit, Type } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Organization } from 'src/Models/organization';
import { OrganizationService } from 'src/services/organization.service';
import { UserRoleAssignmentModel } from 'src/Models/UserRoleAssignmentModel';
import { TypeOrganizationDialogComponent } from 'src/app/shared/TypeOrganizationDialog/TypeOrganizationDialog.component';
import { PersonOrganizationRequest } from 'src/Models/PersonOrganizationRequest';
import { PersonOrganizationService } from 'src/services/personOrganization.service';

@Component({
  selector: 'app-ApproveOrganization',
  templateUrl: './ApproveOrganization.component.html',
  styleUrls: ['./ApproveOrganization.component.css']
})
export class ApproveOrganizationComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'phoneNumber', 'status', 'actions'];
  dataSource!: MatTableDataSource<Organization>;
  typeOrganizations: any;
  ownerId: any;
  personOrganizationReq = <PersonOrganizationRequest>{};

  constructor(
    private organizationService: OrganizationService,
    private _userRole: UserRoleService,
    private _typeOrganization: TypeOrganizationService,
    private dialog: MatDialog,
    private _dialog: DialogService,
    private _personOrganization: PersonOrganizationService
  ) {}

  ngOnInit() {
   this.getOrganizations();
   this.getOrganizationType(); 
  }


  getOrganizations() {
    this.organizationService.getAll().subscribe(organizations => {
      this.dataSource = new MatTableDataSource(organizations);
    });
  }

  getOrganizationType() {
    this._typeOrganization.getAll().subscribe(typeOrganizations => {
      this.typeOrganizations = typeOrganizations;
    });
  }
  approveOrganization(organization: Organization, id: number) {
    const dialogRef = this.dialog.open(TypeOrganizationDialogComponent, {
      width: '450px',
      data: { typeOrganizations: this.typeOrganizations }
    });
    
    this._personOrganization.getOwnerIdByOrganization(id).subscribe((result => {
      this.ownerId = result;
    }
    ));

    dialogRef.afterClosed().subscribe(selectedTypeOrganizationId => {
      if (selectedTypeOrganizationId) {
        organization.typeOrganizationId = selectedTypeOrganizationId;
        organization.status = 'Ativo';
        organization.dataDaAtivacao = new Date();
        organization.dataUltimaAlteracao = new Date();

        this.organizationService.updateOrganization(organization, id).subscribe(() => {
          this.setUserAsOwner();
          this._dialog.openDialog('Sucesso', 'Empresa aprovada com sucesso', 'success');
        });


        if(organization.status == 'Ativo'){
          this.personOrganizationReq = {
            personId: this.ownerId,
            organizationId: id,
            isAdmin: false,
            isOwner: true,
            isBlocked: false,
            isDeleted: false,
            isMember: false
          }
        }

        this._personOrganization.addPersonOrganization(this.personOrganizationReq).subscribe();
      }
    });
  }

denyOrganization(organization: Organization, id: number) {
  organization.status = 'Inativo';
  organization.dataDaDesativacao = new Date();
  this.organizationService.updateOrganization(organization, id).subscribe((result) => {
    console.log(result);
    this._dialog.openDialog('Sucesso', 'Empresa rejeitada com sucesso', 'success');
  });
}


private setUserAsOwner() {
  const user = JSON.parse(localStorage.getItem('app.user') || '{}');

  if (user) {
    //bring the sub in claims to store as a userId
    let userId = user.claims.find((c: any) => c.type === 'sub')?.value;
    let roleId = 'c4316007-99ac-4c8c-8092-d2ddc042e18e';
    let data = <UserRoleAssignmentModel>{};
    data.userId = userId;
    data.roleId = roleId;
    this._userRole.assignUserRole(data).subscribe(res => console.log(res));
}
}

}
