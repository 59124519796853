import { UserService } from './../user.service';
import { PersonService } from 'src/services/person.service';
import { Component, OnInit } from '@angular/core';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menuUser',
  templateUrl: './menuUser.component.html',
  styleUrls: ['./menuUser.component.css']
})
export class MenuUserComponent {

  faRightToBracket = faRightToBracket;
  saudacao: string = '';
  possuiFoto = false;
  env = environment.root;
  nomeFoto: any;
  constructor(private userService: UserService,
    private router: Router) {  }

  userLogado(): boolean {
    var user = this.userService.obterUsuario();
    if (!(Object.keys(user).length === 0)) {
      let name = user.nome.split(' ');
      this.saudacao = "Olá " + name[0];
      if (user.nomeFoto != '' && user.nomeFoto != null) {
        this.possuiFoto = true;
        this.nomeFoto = user.nomeFoto;
      }
      return true;
    }

    return false;
  }

  logout(){
    this.userService.logout();
    this.router.navigateByUrl('/login');
  }


}
