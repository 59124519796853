<app-PersonalNav [title]="label"></app-PersonalNav>
<div class="container d-flex justify-content-start">
  
  <button mat-raised-button color="white" *ngIf="id" (click)="addNewColaborator()">Adicionar Colaborador</button>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
<div class="container mt-3">
  
  <form [formGroup]="solutionForm" (ngSubmit)="onSubmit()" class="profile-form">
    
    <!-- Organization -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="organization">Nome da Empresa <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="organization" formControlName="organizationResponsibleName">
      </div>
    </div>

    <!-- Area -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="area">Área da Solução <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select name="area" formControlName="typeOfSolution" class="form-select">
          <ng-container *ngFor="let group of formationAreas">
            <optgroup label="{{ group.classification }}">
              <option value="" disabled selected>Selecione uma opção</option>
              <option *ngFor="let item of group.items" [value]="item.name">{{item.name}}</option>
            </optgroup>
          </ng-container>
        </select>
      </div>
    </div>

    <!-- Contribution type - with select -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="contributionType">Tipo de Vínculo <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select name="contributionType" formControlName="contributionType" class="form-select">
          <option value="" disabled selected>Selecione uma opção</option>
          <option *ngFor="let item of contributionTypes" [value]="item.value">{{item.label}}</option>
        </select>
      </div>
    </div>

  
    <!-- Objectives -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="objectives">Nome da Solução <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input class="form-control" id="objectives"  formControlName="objectives">
      </div>
    </div>

    <!-- Challenge Description -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="challenge">Descrição da Solução <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input class="form-control" id="description" formControlName="description">
      </div>
    </div>


    <!-- MVP Features -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="mvpFeatures">Categoria Específica da Solução <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input class="form-control" id="mvpFeatures"  formControlName="mvpFeat">
      </div>
    </div>

    <!-- Operational Gains -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="operationalGains">Ganhos Operacionais</label>
      </div>
      <div class="col-sm-8">
        <input class="form-control" id="operationalGains"  formControlName="operationalGain">
      </div>
    </div>

    <!-- Economic Gains -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="economicGains">Ganhos Econômicos</label>
      </div>
      <div class="col-sm-8">
        <input class="form-control" id="economicGains"  formControlName="economicGain">
      </div>
    </div>

    <!-- Desired Maturity -->
<!-- Desired Maturity -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="desiredMaturity">Maturidade da Solução (TRL) <span class="required-indicator">*</span></label>
  </div>
  <div class="col-sm-8">
    <select class="form-select" name="desiredMaturity" formControlName="trl" id="trl">
        <option value="" disabled selected>Selecione uma opção</option>
        <option *ngFor="let item of trl" [value]="item.value" matTooltip="{{item.descricao}}">
            {{item.value}} - {{item.label}} - {{item.classificacao}}
    </select>
  </div>
</div>

    <!-- Available Funds -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="availableFunds">Investimento Necessário <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="availableFunds" formControlName="availableFees"  mask="separator.3" prefix="R$" thousandSeparator="." decimalMarker="," [dropSpecialCharacters]="false">
      </div>
    </div>

    <!-- Annex -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="annex">Anexo - Documento da Solução</label>
      </div>
      <div class="col-sm-8">
        <input type="file" class="form-control" id="annex" formControlName="annex" accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,image/*">
      </div>
    </div>

    <!-- Video Link -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="videoLink">Link do Vídeo de Apresentação</label>
      </div>
      <div class="col-sm-8">
        <input type="url" class="form-control" id="videoLink" formControlName="videoLink">
      </div>
    </div>

    <!-- Submit Button -->
    <div class="form-group row">
      <div class="col-sm-12 d-flex justify-content-end">
        <button mat-raised-button color="warn" type="submit" class="btn btn-primary">Salvar</button>
      </div>
    </div>

    <!-- <span>{{solutionForm.value | json}}</span> -->
  </form>
</div>
