import { AccessService } from './../../services/access.service';
import { DialogService } from './../../services/dialog.service';
import { FormationAreaService } from './../../services/formationArea.service';
import { FormationArea } from './../../Models/formationArea';
import { RoleTypes } from './../../Models/roleTypes';
import { UserService } from './../../api/base/user/user.service';
import { Organization } from './../../Models/organization';
import { TypeOrganization } from './../../Models/typeOrganization';
import { Person } from './../../Models/person';
import { Component, OnInit, Type, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PersonService } from 'src/services/person.service';
import { TypeOrganizationService } from 'src/services/typeOrganization.service';
import { OrganizationService } from 'src/services/organization.service';
import { User } from 'src/api/base/user/user';
import { typeRoles } from 'src/Models/typeRoles';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, filter, take } from 'rxjs';
import { PersonOrganizationService } from 'src/services/personOrganization.service';
import { RoleService } from 'src/services/role.service';

@Component({
  selector: 'app-PersonForm',
  templateUrl: './PersonForm.component.html',
  styleUrls: ['./PersonForm.component.css']
})
export class PersonFormComponent implements OnInit {
  institutionsLoaded$ = new BehaviorSubject<boolean>(false);
  personForm!: FormGroup;
  person!: Person;
  loggedUser!: User;
  user: any;
  roleSearched: any;
  showOrganizationInput: boolean = false;
  newOrganizationName: string = '';
  personOrganizationData: any;

roleToDetailMapping: any = {
    'Professor': { formGroup: 'professorDetails', field: 'educationInstitution', newOrgField: 'newOrganizationName' },
    'Student': { formGroup: 'studentDetails', field: 'educationInstitution', newOrgField: 'newOrganizationName' },
    'Researcher': { formGroup: 'researcherDetails', field: 'educationInstitution', newOrgField: 'newOrganizationName' },
    'Entrepreneur': { formGroup: 'entrepreneurDetails', field: 'company', newOrgField: 'newOrganizationName' },
    'Investor': { formGroup: 'investorDetails', field: 'company', newOrgField: 'newOrganizationName' },
    'PublicAgentState': { formGroup: 'publicAgentDetailsState', field: 'company', newOrgField: 'newOrganizationName' },
    'PublicAgentCity': { formGroup: 'publicAgentDetailsCity', field: 'company', newOrgField: 'newOrganizationName' },
    'PublicAgentFederal': { formGroup: 'publicAgentDetailsCountry', field: 'company', newOrgField: 'newOrganizationName' },
    'LegislativeAgent': { formGroup: 'publicAgentDetailsLeg', field: 'company', newOrgField: 'newOrganizationName' },
    'ExecutiveAgent': { formGroup: 'publicAgentDetailsExec', field: 'company', newOrgField: 'newOrganizationName' },
    'JudicialAgent': { formGroup: 'publicAgentDetailsJud', field: 'company', newOrgField: 'newOrganizationName' },
    'CommonUser': { formGroup: 'commonUserDetails', field: 'organization', newOrgField: 'newOrganizationName' },
    'PrivateAgent': { formGroup: 'privateAgentDetails', field: 'company', newOrgField: 'newOrganizationName' },
  };


  roleTypes = [
    { value: RoleTypes.Professor, label: 'Professor' },
    { value: RoleTypes.Estudante, label: 'Estudante' },
    { value: RoleTypes.Pesquisador, label: 'Pesquisador' },
    { value: RoleTypes.Empresario, label: 'Empresário' },
    { value: RoleTypes.Investidor, label: 'Investidor' },
    { value: RoleTypes.Funcionario_Publico_Estado, label: 'Funcionário Público - Estado' },
    { value: RoleTypes.Funcionario_Publico_Municipio, label: 'Funcionário Público - Município' },
    { value: RoleTypes.Funcionario_Publico_Federal, label: 'Funcionário Público - Federal' },
    { value: RoleTypes.Funcionario_Publico_Legislativo, label: 'Funcionário Público - Legislativo' },
    { value: RoleTypes.Funcionario_Publico_Executivo, label: 'Funcionário Público - Executivo' },
    { value: RoleTypes.Funcionario_Publico_Judiciario, label: 'Funcionário Público - Judiciário' },
    { value: RoleTypes.Empregado_Empresa_Privada, label: 'Empregado de Empresa Privada' },
  ];

  typeOrganizations!: TypeOrganization[];
  organizations!: Organization[];
  institutions!: Organization[];
  companies!: Organization[];

  formationAreas!: any[];

  formationDegrees = [
    'Médio - Técnico',
    'Superior - Tecnólogo',
    'Graduação - Bacharel',
    'Pós-Graduação - MBA',
    'Pós-Graduação - Mestrado',
    'Pós-Graduação - Doutorado (Ph.D.)',
    'Pós-Doutorado'
  ]
 
  defaultOrganization!: Organization;
  companiesFromGov!: Organization[];
  hubs!: Organization[];
  currentRole: any;
  isLoading = false;
  organizationUser!: Organization[];
  firstOrganization!: Organization;
  
  constructor(private fb: FormBuilder,
    private _person: PersonService,
    private _user: UserService,
    private _dialog: DialogService,
    private _organization: OrganizationService,
    private _formationArea: FormationAreaService,
    private _access: AccessService,
    private _toastr: ToastrService,
    private _type: TypeOrganizationService,
    private renderer: Renderer2,
    private _personOrganization: PersonOrganizationService,
    private _role: RoleService
  ) {
      this.personForm = this.fb.group({
        name: ['', [Validators.required, Validators.maxLength(50)]],
        email: ['', [Validators.required, Validators.email]],
        birthDate: ['', Validators.required],
        mobileNumber: ['', Validators.required],
        cpf:['', Validators.required],
        whatsAppNumber:['', Validators.required, Validators.pattern('^[0-9]*$')],
        address:['', Validators.required],
        formationDegree:['Selecione o Grau de Formação', Validators.required],
        formationMajorArea:['Selecione a área de formação', Validators.required],
        graduationArea:['Selecione a área de Graduação', Validators.required],
        formationPending:['Selecione a formação em Andamento'],
        formationPendingArea:['Selecione a área de formação em Andamento'],
        firstInterestedArea:['Selecione a área de interesse 1', Validators.required],
        secondInterestedArea:['Selecione a área de interesse 2', Validators.required],
        linkedin:[''],
        urlCurriculum:[''],
        urlFoto:[''],
        role: ['Selecione o Tipo', Validators.required], // Simplified to a single role selection
        professorDetails: this.fb.group({
          educationInstitution: ['', Validators.required]
        }).disable(),
        studentDetails: this.fb.group({
          educationInstitution: ['', Validators.required]
        }).disable(),
        researcherDetails: this.fb.group({
          educationInstitution: ['', Validators.required]
        }).disable(),
        entrepreneurDetails: this.fb.group({
          company: ['', Validators.required]
        }).disable(),
        investorDetails: this.fb.group({
          company: ['', Validators.required]
        }).disable(),
        publicAgentDetailsState: this.fb.group({
          company: ['', Validators.required]

          // Public agent-specific fields
        }).disable(),
        publicAgentDetailsCity: this.fb.group({
          company: ['', Validators.required]
          // Public agent-specific fields
        }).disable(),
        publicAgentDetailsCountry: this.fb.group({
          company: ['', Validators.required]
          // Public agent-specific fields
        }).disable(),
        publicAgentDetailsLeg: this.fb.group({
          company: ['', Validators.required]
          // Public agent-specific fields
        }).disable(),
        publicAgentDetailsExec: this.fb.group({
          company: ['', Validators.required]
          // Public agent-specific fields
        }).disable(),
        publicAgentDetailsJud: this.fb.group({
          company: ['', Validators.required]
          // Public agent-specific fields
        }).disable(),
        commonUserDetails: this.fb.group({
          organization: ['']
          // Common user-specific fields
        }).disable(),
        privateAgentDetails: this.fb.group({
          company: ['', Validators.required]
          // Private agent-specific fields
        }).disable(),
        
      });
    }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    //this.getFormationAreas();
  
    try {
      this.getData();
      //this.initializeForm(); // Inicializar o formulário após garantir que os dados do usuário estejam disponíveis
    } catch (error) {
      console.error('Erro durante a inicialização:', error);
      // Aqui você pode adicionar lógica para exibir uma mensagem de erro ao usuário
    }
  }
  

  initializeForm() {
    this.personForm = this.fb.group({
      name: this.user.name || '',
      email: this.user.email || '',
      birthDate: this.user.birthDate ? this.formatDate(this.user.birthDate) : '',
      mobileNumber: this.user.phoneNumber || '',
      cpf: this.user.cpf || '',
      whatsAppNumber: this.user.whatsAppNumber || '',
      address: this.user.address || '',
      formationDegree: this.user.formationDegree || 'Selecione o Grau de Formação',
      formationMajorArea: this.user.formationMajorArea || 'Selecione a área de formação',
      firstInterestedArea: this.user.firstInterestedArea || 'Selecione a área de interesse 1',
      secondInterestedArea: this.user.secondInterestedArea || 'Selecione a área de interesse 2',
      graduationArea: this.user.graduationArea || 'Selecione a área de Graduação',
      formationPending: this.user.formationPending || 'Selecione a formação em Andamento',
      formationPendingArea: this.user.formationPendingArea || 'Selecione a área de formação em Andamento',
      linkedin: this.user.urlLinkedin || '',
      urlCurriculum: this.user.urlCurriculum || '',
      urlFoto: this.user.urlFoto || '',
      role: (this.user.roles && this.user.roles.length > 0) ? this.mapRoleToFormValue(this.user.roles[0].roleType) : '',
        professorDetails: this.fb.group({
            educationInstitution: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
        }),
          studentDetails: this.fb.group({
            educationInstitution: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          researcherDetails: this.fb.group({
            educationInstitution: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          entrepreneurDetails: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          investorDetails: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          publicAgentDetailsState: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          publicAgentDetailsCity: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          publicAgentDetailsCountry: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          publicAgentDetailsLeg: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          publicAgentDetailsExec: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          publicAgentDetailsJud: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          commonUserDetails: this.fb.group({
            organization: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          }),
          privateAgentDetails: this.fb.group({
            company: [{value: '', disabled: true}, Validators.required],
            newOrganizationName: ['']
          })
        });


      //   if (this.user) {
      //     debugger;
      //     this.personForm.patchValue({
      //         name: this.user.name || '',
      //         email: this.user.email || '',
      //         birthDate: this.user.birthDate ? this.formatDate(this.user.birthDate) : '',
      //         mobileNumber: this.user.phoneNumber || '',
      //         cpf: this.user.cpf || '',
      //         whatsAppNumber: this.user.whatsAppNumber || '',
      //         address: this.user.address || '',
      //         formationDegree: this.user.formationDegree || 'Selecione o Grau de Formação',
      //         formationMajorArea: this.user.formationMajorArea || 'Selecione a área de formação',
      //         firstInterestedArea: this.user.firstInterestedArea || 'Selecione a área de interesse 1',
      //         secondInterestedArea: this.user.secondInterestedArea || 'Selecione a área de interesse 2',
      //         graduationArea: this.user.graduationArea || 'Selecione a área de Graduação',
      //         formationPending: this.user.formationPending || 'Selecione a formação em Andamento',
      //         formationPendingArea: this.user.formationPendingArea || 'Selecione a área de formação em Andamento',
      //         linkedin: this.user.urlLinkedin || '',
      //         urlCurriculum: this.user.urlCurriculum || '',
      //         urlFoto: this.user.urlFoto || '',
      //         role: this.mapRoleToFormValue(this.user.roles[0].roleType) || ''
      //     });

      //   // If there is an initial role, trigger the detail forms setup
      //   // if (this.user.roles && this.user.roles.length) {
      //   //     this.toggleDetailForms(this.mapRoleToFormValue(this.user.roles[0].roleType));
      //   //     this.preFillRoleDetails(this.mapRoleToFormValue(this.user.roles[0].roleType));
      //   // }

      // }

        this.personForm.get('role')?.valueChanges.subscribe(value => {
          this.currentRole = value;
          this.toggleDetailForms(value);
          this.preFillRoleDetails(value);
        });


        if (this.personForm.get('role')?.value) {
          this.currentRole = this.personForm.get('role')?.value;
          this.toggleDetailForms(this.currentRole);
          this.preFillRoleDetails(this.mapRoleToFormValue(this.user.roles[0].roleType));
      }

}

  mapRoleToFormValue(roleType: any) {
    switch(roleType) {
      case 0: return 'Professor';
      case 1: return 'Student';
      case 2: return 'Researcher';
      case 3: return 'Entrepreneur';
      case 4: return 'Investor';
      case 5: return 'PublicAgentState';
      case 6: return 'PublicAgentCity';
      case 7: return 'PublicAgentFederal';
      case 8: return 'LegislativeAgent';
      case 9: return 'ExecutiveAgent';
      case 10: return 'JudicialAgent';
      case 11: return 'PrivateAgent';
      case 12: return 'CommonUser';
      // Map other role types similarly
      default: return '';
    }
  }

  preFillRoleDetails(role: string) {
    this._role.getRoleByUserId(this.user.id).subscribe(result => {
      this.roleSearched = result;

      if (this.roleSearched && this.roleSearched.organizationId) {
        this.setOrganizationInForm(this.roleSearched.roleType, this.roleSearched.organizationId, this.roleSearched.organization.name);
        this.firstOrganization = this.roleSearched.organization;
      }
  });
}

  formatDate(date: Date | string): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  convertIsoDateToBrDate(isoDate: string): string {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

 
  toggleDetailForms(role: string) {
    console.log('Disabling all detail forms');
    // Disable all detail forms first
    Object.keys(this.roleToDetailMapping).forEach((key) => {
        this.personForm.get(this.roleToDetailMapping[key].formGroup)?.disable();
    });

    // Enable the correct detail form for the selected role
    const detailConfig = this.roleToDetailMapping[role];
    if (detailConfig) {
        const group = this.personForm.get(detailConfig.formGroup);
        if (group) {
            console.log(`Enabling form group: ${detailConfig.formGroup}`);
            group.enable();
        }
    }
}

setOrganizationInForm(role: string, organizationId: number, newOrgName?: string) {
    const group = this.personForm.get(this.roleToDetailMapping[this.mapRoleToFormValue(role)].formGroup);
    if (group) {
        const control = group.get(this.roleToDetailMapping[this.mapRoleToFormValue(role)].field);
        if (control) {
            if (control.value !== newOrgName) { // Only update if the value is different
                control.enable(); // Ensure the control is enabled before setting the value
                control.setValue(organizationId);  // Set the value to the organization ID
            } else {
                console.log('The value is already set, skipping...');
            }
        }
    }
}


async getData(): Promise<void> {
  try {
    let loggedUser = this._user.obterUsuario();
    this.user = await this._person.getById(loggedUser.id).toPromise();
    this.getPersonOrganization();

    // // Aguarde o carregamento das áreas de formação
    await this.getFormationAreas();

    // Aguarde o carregamento da organização padrão
    await this.fetchDefaultOrganization();

  } catch (error) {
    console.error('Erro ao carregar os dados do usuário:', error);
    throw error; // Rejeita a promise se houver algum erro
  }
}

// Bring PersonOrganization
getPersonOrganization() {
  this._personOrganization.getPersonOrganizationByPerson(this.user.id).subscribe(result => {
    this.personOrganizationData = result;
    console.log('Person Organization:', this.personOrganizationData);
    localStorage.setItem('personOrganization', JSON.stringify(this.personOrganizationData));
  });
}

getFormationAreas() {
  this._formationArea.getAll().subscribe(result => {
      this.formationAreas = this.groupFormationAreas(result);
      console.log('Data of the form before initializing:', this.personForm.value);
      this.initializeForm(); // Initialize the form only after data is 
      console.log('AfterFirstInitialization:', this.personForm.value);
  });
}

  groupFormationAreas(areas: any[]) {
    const grouped = new Map();
  
    areas.forEach((area) => {
      const classification = grouped.get(area.classification) || [];
      classification.push(area);
      grouped.set(area.classification, classification);
    });

    //sort the classification and the subjects in the classification
    grouped.forEach((value, key) => {
      value.sort((a: FormationArea, b: FormationArea) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });

  
    return Array.from(grouped, ([classification, items]) => ({ classification, items }));
  }

  fetchDefaultOrganization() {
    // Service call to get the default organization (e.g., "Sem Vínculo") and set it to this.defaultOrganization
    this._organization.getDefaultOrganization().subscribe(org => {
      this.defaultOrganization = org;
    });
  }

  fetchOrganizationsByClassification(classification: string) {
    this._organization.getOrganizationByClassification(classification).subscribe({
        next: (orgs: Organization[]) => {
            console.log('Fetched organizations:', orgs);

            // Set the institutions based on classification
            switch (classification) {
                case 'Ensino e Pesquisa':
                    this.institutions = orgs;
                    break;
                case 'Empresa':
                    this.companies = orgs;
                    break;
                case 'Governo e Orgãos Públicos':
                    this.companiesFromGov = orgs;
                    break;
                case 'Startups':
                    this.companies = orgs;
                    break;
                case 'Hubs e Coworkings':
                    this.hubs = orgs;
                    break;
                case 'Investimentos e Fomento':
                    this.companies = orgs;
                    break;
                default:
                    this.organizations = orgs;
            }

            // Notify that institutions have been loaded
            this.institutionsLoaded$.next(true);
        },
        error: (err: any) => {
            console.error('Failed to fetch organizations by classification', err);
            this.institutionsLoaded$.next(false); // Set to false on error
        }
    });
}

  //Format date to dd-MM-yyyy
  convertDate() {
    let date = this.personForm.get('birthDate')?.value;
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  getBirthDate() {
    return this.personForm.get('birthDate')?.value;
  }

  resetForm() {
    this.personForm.reset();
  }

  onSubmit() {

    this.isLoading = true;
    debugger;
    if (this.personForm.valid) {
      const formData = this.prepareFormData();
      this.createPerson(formData);

    } else {
      this.isLoading = false;
      console.error('Form is not valid');

    }
  }
  

  prepareFormData() {
    const formData = this.personForm.value;

    // Check which role is selected and check if the details are empty
    switch (formData.role) {
      case RoleTypes.Professor:
        if(formData.professorDetails.educationInstitution === '') {
          const educationInstitutionElement = this.renderer.selectRootElement('#educationInstitution', true);
          formData.professorDetails.educationInstitution = educationInstitutionElement?.textContent || '';
        }
        break;
      case RoleTypes.Estudante:
        if(formData.studentDetails.educationInstitution === '') {
          const educationInstitutionElement = this.renderer.selectRootElement('#educationInstitution', true);
          formData.studentDetails.educationInstitution = educationInstitutionElement?.textContent || '';
        }
        break;
      case RoleTypes.Pesquisador:
        if(formData.researcherDetails.educationInstitution === '') {
          const educationInstitutionElement = this.renderer.selectRootElement('#educationInstitution', true);
          formData.researcherDetails.educationInstitution = educationInstitutionElement?.textContent || '';
        }
        break;
      case RoleTypes.Empresario:
        if(formData.entrepreneurDetails.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.entrepreneurDetails.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Investidor:
        if(formData.investorDetails.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.investorDetails.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Funcionario_Publico_Estado:
        if(formData.publicAgentDetailsState.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.publicAgentDetailsState.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Funcionario_Publico_Municipio:
        if(formData.publicAgentDetailsCity.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.publicAgentDetailsCity.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Funcionario_Publico_Federal:
        if(formData.publicAgentDetailsCountry.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.publicAgentDetailsCountry.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Funcionario_Publico_Legislativo:
        if(formData.publicAgentDetailsLeg.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.publicAgentDetailsLeg.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Funcionario_Publico_Executivo:
        if(formData.publicAgentDetailsExec.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.publicAgentDetailsExec.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Funcionario_Publico_Judiciario:
        if(formData.publicAgentDetailsJud.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.publicAgentDetailsJud.company = companyElement?.textContent || '';
        }
        break;
      case RoleTypes.Empregado_Empresa_Privada:
        if(formData.privateAgentDetails.company === '') {
          const companyElement = this.renderer.selectRootElement('#company', true);
          formData.privateAgentDetails.company = companyElement?.textContent || '';
        }
        break;
      default:
        console.error('Role not found');
    }

    // Add default organization if no specific organization is selected
    if (!formData.organization) {
      formData.organization = this.defaultOrganization.organizationId;
    }
    return formData;
  }

  checkFormValidity() {
    Object.keys(this.personForm.controls).forEach(key => {
      const controlErrors = this.personForm.get(key)?.errors;
      if (controlErrors) {
        console.log('Form Control: ' + key, controlErrors);
      }
    });
  }

  
  transformPerson() {
    const personId = this.user.id; // Assuming this.user is correctly populated
    const targetType = this.personForm.get('role')?.value;
  
    let additionalData = {};
  
    const detailConfig = this.roleToDetailMapping[targetType];
    if (detailConfig) {
      const detailFormGroup = this.personForm.get(detailConfig.formGroup);
      if (detailFormGroup && detailFormGroup.enabled) {
        const fieldValue = detailFormGroup.get(detailConfig.field)?.value;
        const newOrgName = detailFormGroup.get(detailConfig.newOrgField)?.value;
  
        // Check if 'none' was selected for the organization/company
        if (fieldValue === 'none' && newOrgName) {
          additionalData = { newOrganizationName: newOrgName };
        } else if (fieldValue && fieldValue !== 'none') {
          additionalData = { OrganizationId: fieldValue };
        }
      }
    }
    // Only one conditional block to handle additionalData
    if (Object.keys(additionalData).length === 0) {
      console.error('No additional data found for the transformation request.');
      // Handle the case where additional data is necessary but not available
    } else {
      this.transformPersonToOther(personId, targetType, additionalData);
    }
  }
  

  createPerson(person: any) {


    this._person.addPerson(person).subscribe(
      () => {
        this.isLoading = false;
        this._dialog.openDialog('Sucesso', 'Dados foram alterados com sucesso!', 'success');
        
        this.personForm.reset(); // Reset form on success

      },
      (error) => {
        this.isLoading = false;
        this._dialog.openDialog('Erro', 'Erro ao alterar os dados!', 'error');
        console.error(error);
      }
    );
  }

  prepareAdditionalData(targetType: string) {
    let organizationId = null;
  
    // Example mappings - adjust according to your actual form structure and requirements
    const eduOrganizations = ['Universidade', 'Fundação', 'Instituto de Educação', 'Escola', 'Faculdade', 'Instituto Tecnológico e de Pesquisa', 'Centros de Formação Profissional', 'Academias Militares'];
    const govOrganizations = ['Governo Executivo', 'Legislativo', 'Judiciário', 'Ministérios', 'Secretarias', 'Órgãos de Fiscalização e Controle', 'Agências', 'Instituições Financeiras Governamentais'];
    const businessOrganizations = ['Comércio', 'Indústria', 'Startup', 'Serviços', 'Empresa de Tecnologia', 'Empresa de Varejo', 'Empresa de Atacado', 'Empresa Serviço Financeiro', 'Empresa de Saúde', 'Empresa de Biotecnologia', 'Empresa de Energia', 'Empresas de Transporte e Logística', 'Empresas de Artes, Entretenimento e Mídia', 'Empresas de Alimentação', 'Empresas Sociais', 'Empresas de Turismo e Hospitalidade', 'Empresa do Imobiliário', 'Empresa do Setor Automotivo', 'Empresa de Meio Ambiente', 'Empresa de Construção', 'Empresa de Moda e Design', 'Empresa de Telecom'];
  
    switch (targetType) {
      case RoleTypes.Professor:
      case RoleTypes.Estudante:
      case RoleTypes.Pesquisador:
        organizationId = this.getOrganizationIdFromSelection(eduOrganizations);
        break;
      case RoleTypes.Empresario:
      case RoleTypes.Investidor:
        organizationId = this.getOrganizationIdFromSelection(businessOrganizations);
        break;
      case RoleTypes.Funcionario_Publico_Estado:
      case RoleTypes.Funcionario_Publico_Municipio:
      case RoleTypes.Funcionario_Publico_Federal:
      case RoleTypes.Funcionario_Publico_Legislativo:
      case RoleTypes.Funcionario_Publico_Executivo:
      case RoleTypes.Funcionario_Publico_Judiciario:
        organizationId = this.getOrganizationIdFromSelection(govOrganizations);
        break;
      case RoleTypes.Empregado_Empresa_Privada:
        organizationId = this.getOrganizationIdFromSelection(businessOrganizations);
        break;
      // Add more cases as necessary
      

      default:
        // CommonUser and roles not requiring a specific organization might be associated with 'Sem Vínculo'
        organizationId = this.defaultOrganization.organizationId;
        break;
    }
  
    return {
      OrganizationId: organizationId
    };
  }
  
  getOrganizationIdFromSelection(allowedOrganizations: string[]): number | null {
    const selectedOrganizationId = this.personForm.get('organization')?.value;
    const selectedOrganization = this.organizations.find(org => org.organizationId === selectedOrganizationId);
  
    if (selectedOrganization && allowedOrganizations.includes(selectedOrganization.name)) {
      return selectedOrganizationId;
    }
  
    return null;
  }
  
  // Helper method to get the name of the detail form group based on the selected role
  getDetailFormGroupNameByRole(role: string): string {
    const mapping: any = {
      // Add your role to form group mapping here
      'Professor': 'professorDetails',
      'Estudante': 'studentDetails',
      'Pesquisador': 'researcherDetails',
      'Empresario': 'entrepreneurDetails',
      'Investidor': 'investorDetails',
      'Funcionario_Publico_Estado': 'publicAgentDetailsState',
      'Funcionario_Publico_Municipio': 'publicAgentDetailsCity',
      'Funcionario_Publico_Federal': 'publicAgentDetailsCountry',
      'Funcionario_Publico_Legislativo': 'publicAgentDetailsLeg',
      'Funcionario_Publico_Executivo': 'publicAgentDetailsExec',
      'Funcionario_Publico_Judiciario': 'publicAgentDetailsJud',
      'Usuario_Comum': 'commonUserDetails',
      'Empregado_Empresa_Privada': 'privateAgentDetails'

      // Add other roles as necessary
    };
  
    return mapping[role] || ''; // Return an empty string or a default value if role is not found
  }
  
  // Helper method to determine whether to use 'organization' or 'company' field based on the role
  getOrganizationOrCompanyFieldNameByRole(role: string): string {
    // Assuming certain roles specifically need to refer to 'company' instead of 'organization'
    const rolesUsingCompany = ['Empresario', 'Investidor', 'Empregado_Empresa_Privada'];
    return rolesUsingCompany.includes(role) ? 'company' : 'organization';
  }
  

  onRoleChange(event: any) {

    var element = event.target as HTMLInputElement;
    const selectedRole = element.value;
  
    //Map roeles to ther correspoding organization classifications
    const roleToOrganizationClassificationMap: any = {
      [RoleTypes.Professor]: 'Ensino e Pesquisa',
      [RoleTypes.Estudante]: 'Ensino e Pesquisa',
      [RoleTypes.Pesquisador]: 'Ensino e Pesquisa',
      [RoleTypes.Empresario]: 'Empresa',
      [RoleTypes.Investidor]: 'Investidor',
      [RoleTypes.Funcionario_Publico_Estado]: 'Governo e Orgãos Públicos',
      [RoleTypes.Funcionario_Publico_Municipio]: 'Governo e Orgãos Públicos',
      [RoleTypes.Funcionario_Publico_Federal]: 'Governo e Orgãos Públicos',
      [RoleTypes.Funcionario_Publico_Legislativo]: 'Governo e Orgãos Públicos',
      [RoleTypes.Funcionario_Publico_Executivo]: 'Governo e Orgãos Públicos',
      [RoleTypes.Funcionario_Publico_Judiciario]: 'Governo e Orgãos Públicos',
      [RoleTypes.Empregado_Empresa_Privada]: 'Empresa',
      [RoleTypes.Sem_Vinculo]: 'Sem Vínculo'
    };

    const classification = roleToOrganizationClassificationMap[selectedRole] || 'Sem Vínculo';
    this.fetchOrganizationsByClassification(classification);
 
    // Enable/disable specific form controls based on the selected role
    this.updateFormControlsBasedOnRole(selectedRole);
  }
  
  updateFormControlsBasedOnRole(selectedRole: any) {
    // Object containing all role-specific form groups
    const roleFormGroups = {
      professorDetails: RoleTypes.Professor,
      studentDetails: RoleTypes.Estudante,
      researcherDetails: RoleTypes.Pesquisador,
      entrepreneurDetails: RoleTypes.Empresario,
      investorDetails: RoleTypes.Investidor,
      publicAgentDetailsState: RoleTypes.Funcionario_Publico_Estado,
      publicAgentDetailsCity: RoleTypes.Funcionario_Publico_Municipio,
      publicAgentDetailsCountry: RoleTypes.Funcionario_Publico_Federal,
      publicAgentDetailsLeg: RoleTypes.Funcionario_Publico_Legislativo,
      publicAgentDetailsExec: RoleTypes.Funcionario_Publico_Executivo,
      publicAgentDetailsJud: RoleTypes.Funcionario_Publico_Judiciario,
      privateAgentDetails: RoleTypes.Empregado_Empresa_Privada,
      commonUserDetails: RoleTypes.Sem_Vinculo
    };

    // Disable all role-specific form groups
    Object.keys(roleFormGroups).forEach(groupName => {
      this.personForm.get(groupName)?.disable();
    });
  
    // Enable the relevant form group for the selected role
    Object.entries(roleFormGroups).forEach(([groupName, roleValue]) => {
      if ((Array.isArray(roleValue) && roleValue.includes(selectedRole)) || roleValue === selectedRole) {
        this.personForm.get(groupName)?.enable();
      }
    });
    console.log(this.personForm);
  }

  private transformPersonToOther(personId: number, targetType: string, additionalData: any) {
    const add = JSON.stringify(additionalData); // Serialize additional data to JSON strin
    const transformationRequest = {
        targetType: targetType, // Adjusted to 'target' as per backend expectation
        additionalData: add, // Ensure this matches the backend's expected field name
        address: this.personForm.get('address')?.value,
        birthDate: this.personForm.get('birthDate')?.value,
        mobileNumber: this.personForm.get('mobileNumber')?.value,
        phoneNumber: this.personForm.get('mobileNumber')?.value,
        cpf: this.personForm.get('cpf')?.value,
        email: this.personForm.get('email')?.value,
        formationDegree: this.personForm.get('formationDegree')?.value,
        formationMajorArea: this.personForm.get('formationMajorArea')?.value,
        graduationArea: this.personForm.get('graduationArea')?.value,
        formationPending: this.personForm.get('formationPending')?.value,
        formationPendingArea: this.personForm.get('formationPendingArea')?.value, 
        firstInterestedArea: this.personForm.get('firstInterestedArea')?.value,
        secondInterestedArea: this.personForm.get('secondInterestedArea')?.value,
        name: this.personForm.get('name')?.value,
        urlCurriculum: this.personForm.get('urlCurriculum')?.value,
        urlLinkedin: this.personForm.get('linkedin')?.value,
        urlFoto: this.personForm.get('urlFoto')?.value,
        whatsAppNumber: this.personForm.get('whatsAppNumber')?.value,
    };

    this._person.transformPersonToOtherType(personId, transformationRequest).subscribe(
        (result) => {
            console.log(result);
            this._toastr.success('Alterações efetuadas com sucesso!');
            this.updateUserDataInLocalStorage(result); // Update the user data in local storage (if necessary
            this.personForm.reset(); // Reset form on success
        },
        (error) => {
            console.error(error);
            ; // Handle error scenario
        }
    );
  }

  updateUserDataInLocalStorage(dataToUpdate: any) {

    const userDataString = localStorage.getItem('app.user');
    const userData = userDataString ? JSON.parse(userDataString) : {};

    userData.completedProfile = dataToUpdate.completedProfile;
    userData.email = dataToUpdate.email;
    userData.firstInterestedArea = dataToUpdate.firstInterestedArea;
    userData.secondInterestedArea = dataToUpdate.secondInterestedArea;
    userData.nomeFoto = dataToUpdate.nomeFoto;

    localStorage.setItem('app.user', JSON.stringify(userData));

}


  // New method to handle file selection and upload
  handleFileNameChange(event: any) {
    if(event == null) {
      return;
    }
    console.log(event);
    this.personForm.get('urlFoto')?.setValue(event);
  }

  // New method to handle selection of a non recorded company or institution
  handleOrganizationSelection(event: any): void {
    const selectedValue = event.target.value;
    if(selectedValue === 'none') {
      this.showOrganizationInput = true;
    } else {
      this.showOrganizationInput = false;

      // Set the selected organization to the form control
      this.newOrganizationName = '';
    }
    
  }
}