import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogMyProposal',
  templateUrl: './dialogMyProposal.component.html',
  styleUrls: ['./dialogMyProposal.component.css']
})
export class DialogMyProposalComponent implements OnInit {
  proposal: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit() {
    this.proposal = this.data.proposal;
  
  }

}
