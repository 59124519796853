import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonSolutionService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }
  
  
  
    public getAll(): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}api/personSolution`);
    }

    public addPersonSolution(personSolution: any): Observable<any> {
      return this.http.post<any>(`${this.baseUrl}api/personSolution`, personSolution);
    }

    public updatePersonSolution(personSolution: any, id: number): Observable<any> {
      return this.http.put<any>(`${this.baseUrl}api/personSolution/${id}`, personSolution);
    }
  
    public getById(id: number): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}api/personSolution/${id}`);
    }

    public getSolutionByPerson(personId: number): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}api/personSolution/person/${personId}`);
    }

    public getSolutionsFromPerson(personId: number): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}api/personSolution/person/solutions/${personId}`);
    }
  
    public getPersonByOrganization(organizationId: number): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}api/personOrganization/organization/${organizationId}`);
    }

}
