import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CnpjService {

  

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {}

  fetchCnpjData(cnpj: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/cnpj/${cnpj}`);
  }
}
